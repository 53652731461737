// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const ARTICLE_SECTIONS = "Rubriky \u010dl\u00e1nk\u016f";
export const STROLLEROPEDIA_MORE_ARTICLES = "Zobraz dal\u0161\u00ed \u010dl\u00e1nky";
export const STROLLEROPEDIA_NEW_ARTICLES_LONG = "Nejnov\u011bj\u0161\u00ed \u010dl\u00e1nky v ko\u010d\u00e1rkopedii";
export const STROLLERS_HOME_ADD_NEW_QUESTION = "Pod\u00edvej se do ko\u010d\u00e1rkov\u00e9 poradny";
export const STROLLERS_HOME_COVER_DESCRIPTION = "Ko\u010d\u00e1rkopedie je nejv\u011bt\u0161\u00ed encyklopedie ko\u010d\u00e1rk\u016f na sv\u011bt\u011b";
export const STROLLERS_HOME_NEW_QUESTIONS_IN_COUNSELLING = "Nov\u00e9 ot\u00e1zky v poradn\u011b";
export const STROLLERS_HOME_NEW_REVIEWS = "Nov\u00e1 hodnocen\u00ed od maminek";
export const STROLLERS_HOME_STROLLERS_OF_year = "Nejnov\u011bj\u0161\u00ed modely ko\u010d\u00e1rk\u016f %(year)s";
export const STROLLERS_HOME_TIPS_FROM_MUMS = "Tipy na ko\u010d\u00e1rky od n\u00e1kupn\u00edch poradky\u0148";
export const STROLLER_STROLLEROPEDIA = "Ko\u010d\u00e1rkopedie";
