import React from 'react';
import styles from './ColorVariants.mscss';

interface OwnProps {
    className?: string;
    colors: any[];
    maxShown?: number;
}

type Props = OwnProps;


export const ColorVariants: React.FunctionComponent<Props> = React.memo(({
    colors, maxShown,
}) => (
    <div className={styles.ColorVariants}>
        {colors.slice(0, maxShown || colors.length).map((color, index) => (
            <div
                key={index}
                className={styles.ColorVariants__color}
                style={{ backgroundColor: '#' + color.color }}
            />
        ))}

        {maxShown !== undefined && colors.length > maxShown && (
            <div className={styles.ColorVariants__more}>+{colors.length - maxShown}</div>
        )}
    </div>
));

ColorVariants.displayName = 'ColorVariants';
