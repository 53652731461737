import cx from 'classnames';
import { dummyPhoto } from 'mk2/apps/strollers/constants';
import { strollersDetailUrl } from 'mk2/apps/strollers/urls';
import { HorizontalScrolling } from 'mk2/components/HorizontalScrolling';
import { ImgCropped, ImgCropMode } from 'mk2/components/ImgCropped';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { url } from 'mk2/helpers/urls';
import { StrollerEntity, StrollerPhoto } from 'mk2/schemas';
import React from 'react';
import styles from './MoreStrollers.mscss';

interface OwnProps {
    className?: string;
    strollers: Array<
        Pick<StrollerEntity, 'id' | 'name' | 'slug'> & {
            previewPhoto: StrollerPhoto;
        }
    >;
    strollerPreviewPhotoMap: Record<number, number>;
    photoObjectsMap: Record<number, StrollerPhoto>;
}

type Props = OwnProps;

export const MoreStrollers: React.FunctionComponent<Props> = React.memo(({
    className, strollers, strollerPreviewPhotoMap, photoObjectsMap,
}) => (
    <HorizontalScrolling className={cx(styles.MoreStrollers, className)}>
        {strollers.map((stroller) => {
            const firstPhoto = stroller.id in strollerPreviewPhotoMap && strollerPreviewPhotoMap[stroller.id]
                in photoObjectsMap ? photoObjectsMap[strollerPreviewPhotoMap[stroller.id]] : dummyPhoto;
            return (
                <Link
                    key={stroller.id}
                    className={styles.MoreStrollers__stroller}
                    to={url(strollersDetailUrl, { slug: stroller.slug })}
                >
                    <ImgCropped
                        className={styles.MoreStrollers__stroller__image}
                        imgUrl={firstPhoto.url100x100}
                        lazy={false}
                        imgWidth={firstPhoto.originalWidth}
                        imgHeight={firstPhoto.originalHeight}
                        mode={ImgCropMode.Embed}
                        width={100}
                        height={100}
                    />
                    <div className={styles.MoreStrollers__stroller__name}>{stroller.name}</div>
                </Link>
            );
        })}
    </HorizontalScrolling>
));

MoreStrollers.displayName = 'MoreStrollers';


interface PlaceholderProps {
    className?: string;
    count?: number;
}

export const MoreStrollersPlaceholder: React.FC<PlaceholderProps> = ({ className, count = 10 }) => (
    <HorizontalScrolling className={cx(styles.MoreStrollers, className)}>
        {Array.from(Array(count).keys()).map((index) => (
            <span key={index} className={styles.MoreStrollers__stroller}>
                <LoadingPlaceholder width={100} height={100} />
                <div className={styles.MoreStrollers__stroller__name}>
                    <LoadingPlaceholder width={80} />
                </div>
            </span>
        ))}
    </HorizontalScrolling>
);

MoreStrollersPlaceholder.displayName = 'MoreStrollersPlaceholder';
