import cx from 'classnames';
import {
    BZ2_ESHOP_PRODUCT_SHORT,
    BZ2_OLD_PRODUCT_SHORT,
} from 'mk/autogenerated/translations/StrollerPrices.b0df0bd0fdccbf259d2345861ad0cb62'
import { formatPrice } from 'mk/bazaar/common/formatters';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import React from 'react';
import styles from './StrollerPrices.mscss';

interface OwnProps {
    className?: string;
    priceUsedMin?: string;
    priceUsedMax?: string;
    minPricedEshopProductPrice?: string;
    maxPricedEshopProductPrice?: string;
}

type Props = OwnProps;

export const StrollerPrices: React.FunctionComponent<Props> = React.memo(({
    className,
    priceUsedMin,
    priceUsedMax,
    minPricedEshopProductPrice,
    maxPricedEshopProductPrice,
}) => {
    return (
        <>
            {minPricedEshopProductPrice && (
                <div className={cx(styles.StrollerPrices__eshop, className)}>
                    <span className={styles.StrollerPrices__eshop__price}>
                        {maxPricedEshopProductPrice !== minPricedEshopProductPrice ? (
                            <>
                                <span className={styles.StrollerPrices__nowrap}>
                                    {formatPrice(minPricedEshopProductPrice)}
                                </span>
                                <> - </>
                                <span className={styles.StrollerPrices__nowrap}>
                                    {formatPrice(maxPricedEshopProductPrice)}
                                </span>
                            </>
                        ) : (
                            <span className={styles.StrollerPrices__nowrap}>
                                {formatPrice(minPricedEshopProductPrice)}
                            </span>
                        )}
                    </span>
                    <span className={styles.StrollerPrices__eshop__badge}>{BZ2_ESHOP_PRODUCT_SHORT}</span>
                </div>
            )}
            {priceUsedMin && (
                <div className={cx(styles.StrollerPrices__old, className)}>
                    <span className={styles.StrollerPrices__old__price}>
                        {priceUsedMax !== priceUsedMin ? (
                            <>
                                <span className={styles.StrollerPrices__nowrap}>
                                    {formatPrice(priceUsedMin)}
                                </span>
                                <> - </>
                                <span className={styles.StrollerPrices__nowrap}>
                                    {formatPrice(priceUsedMax)}
                                </span>
                            </>
                        ) : (
                            <span className={styles.StrollerPrices__nowrap}>
                                {formatPrice(priceUsedMin)}
                            </span>
                        )}
                    </span>
                    <span className={styles.StrollerPrices__old__badge}>{BZ2_OLD_PRODUCT_SHORT}</span>
                </div>
            )}
        </>
    );
});

StrollerPrices.displayName = 'StrollerPrices';

interface PlaceholderProps {}

export const StrollerPricesPlaceholder: React.FunctionComponent<PlaceholderProps> = () => (
    <>
        <div className={styles.StrollerPrices__new}>
            <span className={styles.StrollerPrices__new__price}>
                <LoadingPlaceholder width={140} />
            </span>
        </div>
        <div className={styles.StrollerPrices__old}>
            <span className={styles.StrollerPrices__old__price}>
                <LoadingPlaceholder width={150} />
            </span>
        </div>
    </>
);

StrollerPrices.displayName = 'StrollerPrices';
