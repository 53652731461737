import { strollers_api_v2_home_url, strollers_api_v2_search_strollers_url } from 'mk/urls/functions';
import {
    strollersHomeLoadApi,
    strollersHomeLoadChartApi,
    StrollersHomeLoadAction,
    StrollersHomeLoadApiNormalizedResponse,
    StrollersHomeLoadChartAction,
    StrollersHomeLoadChartApiNormalizedResponse,
    STROLLERS_HOME_LOAD,
    STROLLERS_HOME_LOAD_CHART,
} from 'mk2/apps/strollers/containers/Home/Home.actions';
import { handleXHRGetErrorSaga, normalizeError, XHRAction, XHRGetError } from 'mk2/helpers/api';
import { getLogger } from 'mk2/logger';
import { all, call, put, takeLatest } from 'redux-saga/effects';

const logger = getLogger('strollers/Home.sagas');

interface LoadApiResponse {
    body: {
        entities: StrollersHomeLoadApiNormalizedResponse['entities'];
        result: StrollersHomeLoadApiNormalizedResponse['result'];
        strollersCountPerBrand: Record<number, number>;
        reviewsCountPerBrand: Record<number, number>;
        brandsCount: number;
    };
}

function* load({ xhr }: StrollersHomeLoadAction & XHRAction) {
    yield put(strollersHomeLoadApi.request());

    try {
        const response: LoadApiResponse = yield call(() => xhr.get(strollers_api_v2_home_url()));

        yield put(
            strollersHomeLoadApi.success(
                response.body.strollersCountPerBrand,
                response.body.reviewsCountPerBrand,
                response.body.brandsCount,
                {
                    entities: response.body.entities,
                    result: response.body.result,
                },
            ),
        );
    } catch (error) {
        yield handleXHRGetErrorSaga(error as XHRGetError, logger);
        yield put(strollersHomeLoadApi.failure(normalizeError(error)));
    }
}

interface LoadChartApiResponse {
    body: {
        entities: StrollersHomeLoadChartApiNormalizedResponse['entities'];
        result: StrollersHomeLoadChartApiNormalizedResponse['result'];
        hasMore: boolean;
    };
}

function* loadChart({ xhr, chartType, offset }: StrollersHomeLoadChartAction & XHRAction) {
    yield put(strollersHomeLoadChartApi.request(chartType, offset));

    try {
        const qs: {
            offset: number;
            limit: number;
            recommended_choice?: string;
            order: '-user_score' | 'recommended_choice';
            minReviewsCount?: number;
            inBazaar?: boolean;
        } = {
            offset,
            limit: offset ? 10 : 6,
            order: '-user_score',
        };

        switch (chartType) {
            case 'top':
            case 'popular':
                qs.recommended_choice = chartType;
                break;
            case 'happyMedium':
                qs.recommended_choice = 'happy medium';
                break;
            case 'score':
                qs.minReviewsCount = 5;
                break;
            case 'inBazaar':
                qs.inBazaar = true;
                qs.order = 'recommended_choice';
        }

        const response: LoadChartApiResponse = yield call(() => xhr.get(strollers_api_v2_search_strollers_url(qs)));

        yield put(
            strollersHomeLoadChartApi.success(chartType, offset, response.body.hasMore, {
                entities: response.body.entities,
                result: response.body.result,
            }),
        );
    } catch (error) {
        yield handleXHRGetErrorSaga(error as XHRGetError, logger);
        yield put(strollersHomeLoadChartApi.failure(chartType, offset, normalizeError(error)));
    }
}

export default function* root() {
    yield all([takeLatest(STROLLERS_HOME_LOAD, load), takeLatest(STROLLERS_HOME_LOAD_CHART, loadChart)]);
}
