import { ACTIVE_SERVER_ID } from 'mk/settings';
import { Count } from 'mk2/components/NotificationsBadge';
import { dateLocal } from 'mk2/helpers/timeformat';
import { BlogPostEntity, BlogPostType, CommentParent, ImageEntity } from 'mk2/schemas';

export const COMMENT_TEXTAREA_FORM_NAME = (commentParent: CommentParent) => `COMMENT_TEXT_AREA_FORM-${commentParent.model}-${commentParent.id}`;
export const BLOG_POST_MENU_MORE = (id: number) => `blog-post-menu-more-${id}`;
export const BLOG_POST_VISIBILITY_MENU_MORE = (id: number) => `blog-post-visibility-menu-more-${id}`;
export const BLOG_POST_COMMENT_MENU_MORE = (id: number) => `blog-post-comment-menu-more-${id}`;
export const BLOG_ALBUM_MENU_MORE = (id: number) => `blog-album-menu-more-${id}`;

export const convertFeedsTabsLastVisitCounts = (feedsTabsLastVisitCounts: { [tabName: string]: Count }) => {
    // TBD: when django blog feed will gone, refactor lastVisitCounts fetch, unnecessary conversions, ...
    const { all, friends, popular } = feedsTabsLastVisitCounts;
    const convert = (cnt: Count) => {
        return cnt ? (cnt === '20+' ? 21 : (typeof cnt === 'number' ? cnt : parseInt(cnt, 10))) : null;
    };
    return { all: convert(all), friends: convert(friends), popular : convert(popular) };
};

export const getPostModel = (post: BlogPostEntity) => {
    return {
        [BlogPostType.SHORT_MESSAGE]: 'message',
        [BlogPostType.ALBUM]: 'album',
        [BlogPostType.ARTICLE]: 'article',
        [BlogPostType.AUTOMATIC_MESSAGE]: 'automatic_message',
        [BlogPostType.SHARE]: 'share',
    }[post.type] || 'message';
};

export function getCommentParent(parentPost: BlogPostEntity, parentImage?: ImageEntity): CommentParent {
    return {
        model: parentImage
            ? 'image'
            : getPostModel(parentPost as BlogPostEntity),
        id: parentImage
            ? parentImage.id
            : parentPost.id,
    };
}

export const STROLLERS_GUIDE_USER_ID = {201: 1102271, 202: 991202}[ACTIVE_SERVER_ID];

export function getPostDate(article: Pick<BlogPostEntity, 'createdAt' | 'user' | 'lastChanged'>) {
    return article.lastChanged && article.user.id === STROLLERS_GUIDE_USER_ID
        ? article.lastChanged
        : article.createdAt;
}
