import {
    initialState,
    perSectionInitialState,
    PerSectionState,
    State,
} from 'mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSections.reducers';
import { AppState } from 'mk2/reducers';

export const getStrollerArticlesState = (state: AppState): State =>
    state.containers.strollers.articles || initialState;

export const getStrollerArticlesOfSectionState = (state: AppState, sectionSlug: string): PerSectionState =>
    getStrollerArticlesState(state).sectionsData?.[sectionSlug] || perSectionInitialState;
