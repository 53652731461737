import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { Action } from 'redux';

export interface StrollersHomeLoadApiAction extends Action {}

export const STROLLERS_HOME_LOAD_API_REQUEST = 'STROLLERS_HOME_LOAD_API_REQUEST';

export interface StrollersHomeLoadApiRequestAction extends StrollersHomeLoadApiAction, APIRequestAction {
    readonly type: typeof STROLLERS_HOME_LOAD_API_REQUEST;
}

export interface StrollersHomeLoadApiNormalizedResponse extends NormalizedResponse {
    readonly result: {
        brands: number[];
        guideArticles: number[];
        topStrollers: number[];
        lastReviews: number[];
        lastReviewsStrollers: number[];
        lastCounsellingsPosts: number[];
        wikiArticles: number[];
        thisYearStrollers: number[];
        buyingGuide: number;
        strollersPreviewPhotoMap: Record<number, number>;
        strollersPhotos: number[];
    };
}

export const STROLLERS_HOME_LOAD_API_SUCCESS = 'STROLLERS_HOME_LOAD_API_SUCCESS';

export interface StrollersHomeLoadApiSuccessAction
    extends StrollersHomeLoadApiAction,
        APISuccessAction<StrollersHomeLoadApiNormalizedResponse> {
    readonly type: typeof STROLLERS_HOME_LOAD_API_SUCCESS;
    readonly strollersCountPerBrand: Record<number, number>;
    readonly reviewsCountPerBrand: Record<number, number>;
    readonly brandsCount: number;
}

export const STROLLERS_HOME_LOAD_API_FAILURE = 'STROLLERS_HOME_LOAD_API_FAILURE';

export interface StrollersHomeLoadApiFailureAction extends StrollersHomeLoadApiAction, APIFailureAction {
    readonly type: typeof STROLLERS_HOME_LOAD_API_FAILURE;
}

export const strollersHomeLoadApi = {
    request: (): StrollersHomeLoadApiRequestAction => ({ type: STROLLERS_HOME_LOAD_API_REQUEST }),
    success: (
        strollersCountPerBrand: Record<number, number>,
        reviewsCountPerBrand: Record<number, number>,
        brandsCount: number,
        response: StrollersHomeLoadApiNormalizedResponse,
    ): StrollersHomeLoadApiSuccessAction => ({
        type: STROLLERS_HOME_LOAD_API_SUCCESS,
        strollersCountPerBrand, reviewsCountPerBrand, brandsCount,
        response,
    }),
    failure: (error: any): StrollersHomeLoadApiFailureAction => ({ type: STROLLERS_HOME_LOAD_API_FAILURE, error }),
};

export const STROLLERS_HOME_LOAD = 'STROLLERS_HOME_LOAD';

export interface StrollersHomeLoadAction extends StrollersHomeLoadApiAction {
    readonly type: typeof STROLLERS_HOME_LOAD;
}

export const strollersHomeLoad = (): StrollersHomeLoadAction => ({ type: STROLLERS_HOME_LOAD });

export type ChartType = 'top' | 'happyMedium' | 'popular' | 'score' | 'inBazaar';

export interface StrollersHomeLoadChartApiAction extends Action {
    readonly chartType: ChartType;
    readonly offset: number;
}

export const STROLLERS_HOME_LOAD_CHART_API_REQUEST = 'STROLLERS_HOME_LOAD_CHART_API_REQUEST';

export interface StrollersHomeLoadChartApiRequestAction extends StrollersHomeLoadChartApiAction, APIRequestAction {
    readonly type: typeof STROLLERS_HOME_LOAD_CHART_API_REQUEST;
}

export interface StrollersHomeLoadChartApiNormalizedResponse extends NormalizedResponse {
    readonly result: {
        strollers: number[];
        strollersPreviewPhotoMap: Record<number, number>;
        strollersPhotos: number[];
    };
}

export const STROLLERS_HOME_LOAD_CHART_API_SUCCESS = 'STROLLERS_HOME_LOAD_CHART_API_SUCCESS';

export interface StrollersHomeLoadChartApiSuccessAction
    extends StrollersHomeLoadChartApiAction,
        APISuccessAction<StrollersHomeLoadChartApiNormalizedResponse> {
    readonly type: typeof STROLLERS_HOME_LOAD_CHART_API_SUCCESS;
    readonly hasMore: boolean;
}

export const STROLLERS_HOME_LOAD_CHART_API_FAILURE = 'STROLLERS_HOME_LOAD_CHART_API_FAILURE';

export interface StrollersHomeLoadChartApiFailureAction extends StrollersHomeLoadChartApiAction, APIFailureAction {
    readonly type: typeof STROLLERS_HOME_LOAD_CHART_API_FAILURE;
}

export const strollersHomeLoadChartApi = {
    request: (chartType: ChartType, offset: number): StrollersHomeLoadChartApiRequestAction => ({
        type: STROLLERS_HOME_LOAD_CHART_API_REQUEST,
        chartType,
        offset,
    }),
    success: (
        chartType: ChartType,
        offset: number,
        hasMore: boolean,
        response: StrollersHomeLoadChartApiNormalizedResponse,
    ): StrollersHomeLoadChartApiSuccessAction => ({
        type: STROLLERS_HOME_LOAD_CHART_API_SUCCESS,
        chartType,
        offset,
        hasMore,
        response,
    }),
    failure: (chartType: ChartType, offset: number, error: any): StrollersHomeLoadChartApiFailureAction => ({
        type: STROLLERS_HOME_LOAD_CHART_API_FAILURE,
        chartType,
        offset,
        error,
    }),
};

export const STROLLERS_HOME_LOAD_CHART = 'STROLLERS_HOME_LOAD_CHART';

export interface StrollersHomeLoadChartAction extends StrollersHomeLoadChartApiAction {
    readonly type: typeof STROLLERS_HOME_LOAD_CHART;
}

export const strollersHomeLoadChart = (chartType: ChartType, offset: number): StrollersHomeLoadChartAction => ({
    type: STROLLERS_HOME_LOAD_CHART,
    chartType,
    offset,
});

export const STROLLERS_HOME_SELECT_CHART = 'STROLLERS_HOME_SELECT_CHART';

export interface StrollersHomeSelectChartAction extends Action {
    readonly type: typeof STROLLERS_HOME_SELECT_CHART;
    readonly chartType: ChartType;
}

export const strollersHomeSelectChart = (chartType: ChartType): StrollersHomeSelectChartAction => ({
    type: STROLLERS_HOME_SELECT_CHART,
    chartType,
});
