import {
    STROLLERS_GO_TO_CHARTS,
    STROLLERS_HOME_ALL_BRANDS,
    STROLLERS_HOME_BRANDS_count,
    STROLLERS_HOME_POPULAR_BRANDS,
} from 'mk/autogenerated/translations/BrandCards.c90ac06346afe7ec7645b8a1198fbd62'
import { BrandCard, BrandCardPlaceholder } from 'mk2/apps/strollers/components/BrandCard';
import { StrollerBrandEntity } from 'mk2/apps/strollers/schemas';
import { strollersBrandsUrl, strollersChartsUrl, strollersListUrl } from 'mk2/apps/strollers/urls';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { url } from 'mk2/helpers/urls';
import { interpolate } from 'mk2/services/i18n';
import React from 'react';
import styles from './BrandCards.mscss';

interface OwnProps {
    className?: string;
    brands: StrollerBrandEntity[];
    brandsCount: number;
    strollersCountPerBrand: Record<number, number>;
    reviewsCountPerBrand: Record<number, number>;
}

type Props = OwnProps;

export const BrandCards: React.FunctionComponent<Props> = React.memo(({
    brands, brandsCount, strollersCountPerBrand, reviewsCountPerBrand,
}) => (
    <div className={styles.BrandCards}>
        <h2 className={styles.BrandCards__title}>{STROLLERS_HOME_POPULAR_BRANDS}</h2>
        <div className={styles.BrandCards__brands}>
            {brands.map((brand) => (
                <Link
                    key={brand.id}
                    to={url(strollersListUrl, { slug: brand.slug })}
                    className={styles.BrandCards__brands__brand}
                >
                    <BrandCard
                        name={brand.name}
                        photo={brand.strollerPhoto}
                        strollersCount={strollersCountPerBrand[brand.id]}
                        reviewsCount={reviewsCountPerBrand[brand.id]}
                    />
                </Link>
            ))}
        </div>
        <div className={styles.BrandCards__footer}>
            <div className={styles.BrandCards__footer__links}>
                <Link to={strollersChartsUrl}>{STROLLERS_GO_TO_CHARTS}</Link> |{' '}
                <Link to={strollersBrandsUrl}>{STROLLERS_HOME_ALL_BRANDS}</Link>
            </div>
            <div className={styles.BrandCards__footer__stats}>
                {interpolate(STROLLERS_HOME_BRANDS_count, { count: brandsCount })}
            </div>
        </div>
    </div>

));

BrandCards.displayName = 'BrandCards';

interface PlaceholderProps {
    className?: string;
    style?: React.CSSProperties;
}

export const BrandCardsPlaceholder: React.FunctionComponent<PlaceholderProps> = ({ className, style }) => (
    <div className={styles.BrandCards}>
        <h2 className={styles.BrandCards__title}>{STROLLERS_HOME_POPULAR_BRANDS}</h2>
        <div className={styles.BrandCards__brands}>
            {Array.from(Array(20).keys()).map((index) => (
                <div key={index} className={styles.BrandCards__brands__brand}>
                    <BrandCardPlaceholder />
                </div>
            ))}
        </div>
        <div className={styles.BrandCards__footer}>
            <div className={styles.BrandCards__footer__links}>
                <Link to={strollersChartsUrl}>{STROLLERS_GO_TO_CHARTS}</Link> |{' '}
                <Link to={strollersBrandsUrl}>{STROLLERS_HOME_ALL_BRANDS}</Link>
            </div>
            <div className={styles.BrandCards__footer__stats}>
                <LoadingPlaceholder width={100} />
            </div>
        </div>
    </div>
);

BrandCardsPlaceholder.displayName = 'BrandCardsPlaceholder';
