import uniq from 'lodash-es/uniq';
import {
    StrollersArticlesOfSectionsLoadApiFailureAction,
    StrollersArticlesOfSectionsLoadApiRequestAction,
    StrollersArticlesOfSectionsLoadApiSuccessAction,
    STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_FAILURE,
    STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_REQUEST,
    STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_SUCCESS,
} from 'mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSections.actions';
import { RECENT_SECTION } from 'mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSections.constants';
import {
    StrollersHomeLoadApiSuccessAction,
    STROLLERS_HOME_LOAD_API_SUCCESS,
} from 'mk2/apps/strollers/containers/Home/Home.actions';
import { tupdate } from 'mk2/helpers/types';
import { errorMessage } from 'mk2/reducers/errorMessage';
import { loadingState, LoadingState } from 'mk2/reducers/loadingState';
import { Reducer } from 'redux';

type StrollersArticlesOfSectionsLoadActions =
    | StrollersArticlesOfSectionsLoadApiRequestAction
    | StrollersArticlesOfSectionsLoadApiSuccessAction
    | StrollersArticlesOfSectionsLoadApiFailureAction;

export interface PerSectionState {
    loadingState: LoadingState;
    errorMessage: string;
    articleIds: number[];
    hasMore: boolean;
}

export const perSectionInitialState: PerSectionState = {
    loadingState: LoadingState.INIT,
    errorMessage: null,
    articleIds: null,
    hasMore: null,
};

const perSectionReducer: Reducer<PerSectionState> = (
    state: PerSectionState = perSectionInitialState,
    action: StrollersArticlesOfSectionsLoadActions | StrollersHomeLoadApiSuccessAction,
) => {
    switch (action.type) {
        case STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_REQUEST:
        case STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_FAILURE:
            return {
                ...state,
                loadingState: loadingState(state.loadingState, action),
                errorMessage: errorMessage(state.errorMessage, action),
            };
        case STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_SUCCESS:
            return {
                ...state,
                loadingState: loadingState(state.loadingState, action),
                errorMessage: errorMessage(state.errorMessage, action),
                articleIds: uniq([...(state.articleIds || []), ...(action.response.result.articles || [])]),
                hasMore: action.hasMore,
            };
        case STROLLERS_HOME_LOAD_API_SUCCESS:
            return {
                ...state,
                loadingState: loadingState(state.loadingState, action),
                errorMessage: errorMessage(state.errorMessage, action),
                articleIds: action.response.result.guideArticles,
                hasMore: true,
            };
        default:
            return state;
    }
};


export interface State {
    sectionsData: {
        [sectionSlug: string]: PerSectionState;
    };
    allArticleIds: number[];
}

export const initialState: State = {
    sectionsData: {},
    allArticleIds: [],
};

const reducer: Reducer<State, StrollersArticlesOfSectionsLoadActions | StrollersHomeLoadApiSuccessAction> =
(state: State = initialState, action: StrollersArticlesOfSectionsLoadActions | StrollersHomeLoadApiSuccessAction): State => {
    let key = null;
    switch (action.type) {
        case STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_REQUEST:
        case STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_FAILURE:
            key = action.sectionSlug;
            return {
                ...state,
                sectionsData: tupdate(state.sectionsData, {
                    [key]: perSectionReducer(state.sectionsData[key], action),
                }),
            };
        case STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_SUCCESS:
            key = action.sectionSlug;
            return {
                ...state,
                sectionsData: tupdate(state.sectionsData, {
                    [key]: perSectionReducer(state.sectionsData[key], action),
                }),
                allArticleIds: uniq([...(state.allArticleIds || []), ...(action.response.result.articles || [])]),
            };
        case STROLLERS_HOME_LOAD_API_SUCCESS:
            key = RECENT_SECTION;
            return {
                ...state,
                sectionsData: tupdate(state.sectionsData, {
                    [key]: perSectionReducer(state.sectionsData[key], action),
                }),
                allArticleIds: action.response.result.guideArticles,
            };
        default:
            return state;
    }
};

export default reducer;
