import cx from 'classnames';
import React, { useEffect, useRef } from 'react';
import styles from './HorizontalScrolling.mscss';

interface OwnProps {
    className?: string;
}

type Props = OwnProps;

export const HorizontalScrolling: React.FunctionComponent<Props> = ({ className, children }) => {
    const containerRef = useRef<HTMLDivElement>();

    // Add padding to bottom so that scrollbar is not attached to content
    useEffect(() => {
        const hasScrollbar = containerRef.current.scrollWidth > containerRef.current.clientWidth;
        if (hasScrollbar) {
            containerRef.current.classList.add(styles['HorizontalScrolling--padded']);
        } else {
            containerRef.current.classList.remove(styles['HorizontalScrolling--padded']);
        }
    }, [containerRef, children]);

    return (
        <div className={cx(styles.HorizontalScrolling, className)} ref={containerRef}>
            <div className={styles.HorizontalScrolling__content}>
                {children}
            </div>
        </div>
    );
};

HorizontalScrolling.displayName = 'HorizontalScrolling';
