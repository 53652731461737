import { ACTION_TO_COMPARE_STROLLER, STROLLERS_COMPARISON_REMOVE_ALL_CONFIRM } from 'mk/autogenerated/translations/StrollersComparison.sagas.3681c452e9877f4f8a32a851e8878bc9'
import { isAnonymous } from 'mk/bazaar/common/userUtils';
import {
    strollers_api_v2_comparison_load_url,
    strollers_api_v2_comparison_remove_all_url,
    strollers_api_v2_comparison_store_ordering_url,
    strollers_api_v2_comparison_toggle_url,
} from 'mk/urls/functions';
import {
    strollersComparisonLoadApi,
    strollersComparisonRemoveAllApi,
    strollersComparisonStoreOrderingApi,
    strollersComparisonToggleApi,
    StrollersComparisonLoadAction,
    StrollersComparisonLoadApiNormalizedResponse,
    StrollersComparisonRemoveAllAction,
    StrollersComparisonStoreOrderingAction,
    StrollersComparisonToggleAction,
    StrollersComparisonToggleApiNormalizedResponse,
    STROLLERS_COMPARISON_LOAD,
    STROLLERS_COMPARISON_REMOVE_ALL,
    STROLLERS_COMPARISON_STORE_ORDERING,
    STROLLERS_COMPARISON_TOGGLE,
} from 'mk2/apps/strollers/containers/StrollersComparison/StrollersComparison.actions';
import { strollersUrl } from 'mk2/apps/strollers/urls';
import { handleXHRPostErrorSaga, normalizeError, XHRAction, XHRPostError } from 'mk2/helpers/api';
import { getLogger } from 'mk2/logger';
import { getRequestUser } from 'mk2/reducers';
import { checkIfUserIsLoggedIn } from 'mk2/sagas/auth';
import { redirectInPWASaga } from 'mk2/services/browserHistory';
import React from 'react';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

const logger = getLogger('strollers/StrollersComparison.sagas');

interface LoadApiResponse {
    body: {
        entities: StrollersComparisonLoadApiNormalizedResponse['entities'];
        result: StrollersComparisonLoadApiNormalizedResponse['result'];
        count: number;
        ids: number[];
    };
}

function* load({ xhr }: StrollersComparisonLoadAction & XHRAction) {
    // Do not load comparion for non-authenticated users
    const requestUser = yield select(getRequestUser);
    if (isAnonymous(requestUser)) {
        return;
    }

    yield put(strollersComparisonLoadApi.request());
    try {
        const response: LoadApiResponse = yield call(() => xhr.get(strollers_api_v2_comparison_load_url()));

        yield put(
            strollersComparisonLoadApi.success(response.body.count, response.body.ids, {
                entities: response.body.entities,
                result: response.body.result,
            }),
        );
    } catch (error) {
        yield handleXHRPostErrorSaga(error as XHRPostError, logger);
        yield put(strollersComparisonLoadApi.failure(normalizeError(error)));
    }
}

interface ToggleApiResponse {
    body: {
        entities: StrollersComparisonToggleApiNormalizedResponse['entities'];
        result: StrollersComparisonToggleApiNormalizedResponse['result'];
        added: boolean;
        count: number;
        ids: number[];
    };
}

function* toggle({ id, xhr }: StrollersComparisonToggleAction & XHRAction) {
    if (!(yield call(checkIfUserIsLoggedIn, ACTION_TO_COMPARE_STROLLER))) {
        return;
    }

    yield put(strollersComparisonToggleApi.request(id));

    try {
        const response: ToggleApiResponse = yield call(() => xhr.post(strollers_api_v2_comparison_toggle_url(), { id }));
        yield put(
            strollersComparisonToggleApi.success(id, response.body.added, response.body.count, response.body.ids, {
                entities: response.body.entities,
                result: response.body.result,
            }),
        );
    } catch (error) {
        logger.error(error);
        yield put(strollersComparisonToggleApi.failure(id, normalizeError(error)));
    }
}

function* removeAll({ xhr }: StrollersComparisonRemoveAllAction & XHRAction) {
    if (!(yield call(checkIfUserIsLoggedIn, ACTION_TO_COMPARE_STROLLER))) {
        return;
    }

    const confirmation = window.confirm(STROLLERS_COMPARISON_REMOVE_ALL_CONFIRM);

    if (!confirmation) {
        // User didn't confirm the action
        return;
    }

    yield put(strollersComparisonRemoveAllApi.request());
    try {

        yield call(() => xhr.post(strollers_api_v2_comparison_remove_all_url()));

        yield put(
            strollersComparisonRemoveAllApi.success({
                entities: {},
                result: {},
            }),
        );
        yield redirectInPWASaga(strollersUrl);
    } catch (error) {
        yield handleXHRPostErrorSaga(error as XHRPostError, logger);
        yield put(strollersComparisonRemoveAllApi.failure(normalizeError(error)));
    }
}

function* storeOrdering({ orderedIds, xhr }: StrollersComparisonStoreOrderingAction & XHRAction) {
    if (!(yield call(checkIfUserIsLoggedIn, ACTION_TO_COMPARE_STROLLER))) {
        return;
    }

    yield put(strollersComparisonStoreOrderingApi.request(orderedIds));
    try {
        // TODO: @gabo api call: input id, direction / output strollers
        yield call(() => xhr.post(strollers_api_v2_comparison_store_ordering_url(), { orderedIds }));

        yield put(
            strollersComparisonStoreOrderingApi.success(orderedIds, {
                entities: {},
                result: {},
            }),
        );
    } catch (error) {
        yield handleXHRPostErrorSaga(error as XHRPostError, logger);
        yield put(strollersComparisonStoreOrderingApi.failure(orderedIds, normalizeError(error)));
    }
}

export default function* root() {
    yield all([
        takeLatest(STROLLERS_COMPARISON_LOAD, load),
        takeEvery(STROLLERS_COMPARISON_TOGGLE, toggle),
        takeLatest(STROLLERS_COMPARISON_REMOVE_ALL, removeAll),
        takeLatest(STROLLERS_COMPARISON_STORE_ORDERING, storeOrdering),
    ]);
}
