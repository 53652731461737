import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { Action } from 'redux';

export interface StrollersArticlesOfSectionsLoadApiAction extends Action {
    readonly sectionSlug: string;
    readonly after: number;
    readonly limit: number;
}

export const STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_REQUEST = 'STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_REQUEST';

export interface StrollersArticlesOfSectionsLoadApiRequestAction extends StrollersArticlesOfSectionsLoadApiAction, APIRequestAction {
    readonly type: typeof STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_REQUEST;
}

export interface StrollersArticlesOfSectionsLoadApiNormalizedResponse extends NormalizedResponse {
    readonly result: { articles: number[] };
}

export const STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_SUCCESS = 'STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_SUCCESS';

export interface StrollersArticlesOfSectionsLoadApiSuccessAction
    extends StrollersArticlesOfSectionsLoadApiAction,
        APISuccessAction<StrollersArticlesOfSectionsLoadApiNormalizedResponse> {
    readonly type: typeof STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_SUCCESS;
    readonly hasMore: boolean;
}

export const STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_FAILURE = 'STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_FAILURE';

export interface StrollersArticlesOfSectionsLoadApiFailureAction extends StrollersArticlesOfSectionsLoadApiAction, APIFailureAction {
    readonly type: typeof STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_FAILURE;
}

export const strollersArticlesOfSectionsLoadApi = {
    request: (
        sectionSlug: string,
        after: number,
        limit: number,
    ): StrollersArticlesOfSectionsLoadApiRequestAction => ({
        type: STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_REQUEST,
        sectionSlug,
        after,
        limit,
    }),
    success: (
        sectionSlug: string,
        after: number,
        limit: number,
        hasMore: boolean,
        response: StrollersArticlesOfSectionsLoadApiNormalizedResponse,
    ): StrollersArticlesOfSectionsLoadApiSuccessAction => ({
        type: STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_SUCCESS,
        sectionSlug,
        after,
        limit,
        hasMore,
        response,
    }),
    failure: (
        sectionSlug: string,
        after: number,
        limit: number,
        error: any,
    ): StrollersArticlesOfSectionsLoadApiFailureAction => ({
        type: STROLLERS_ARTICLES_OF_SECTIONS_LOAD_API_FAILURE,
        sectionSlug,
        after,
        limit,
        error,
    }),
};

export const STROLLERS_ARTICLES_OF_SECTIONS_LOAD_TRIGGER = 'STROLLERS_ARTICLES_OF_SECTIONS_LOAD_TRIGGER';

export interface StrollersArticlesOfSectionsLoadTriggerAction extends StrollersArticlesOfSectionsLoadApiAction {
    readonly type: typeof STROLLERS_ARTICLES_OF_SECTIONS_LOAD_TRIGGER;
}

export const strollersArticlesOfSectionsLoadTrigger = (
    sectionSlug: string,
    after: number,
    limit: number,
): StrollersArticlesOfSectionsLoadTriggerAction => ({
    type: STROLLERS_ARTICLES_OF_SECTIONS_LOAD_TRIGGER,
    sectionSlug,
    after,
    limit,
});
