import {
    ARTICLE_SECTIONS,
    STROLLER_STROLLEROPEDIA,
    STROLLEROPEDIA_MORE_ARTICLES,
    STROLLEROPEDIA_NEW_ARTICLES_LONG,
    STROLLERS_HOME_ADD_NEW_QUESTION,
    STROLLERS_HOME_COVER_DESCRIPTION,
    STROLLERS_HOME_NEW_QUESTIONS_IN_COUNSELLING,
    STROLLERS_HOME_NEW_REVIEWS,
    STROLLERS_HOME_STROLLERS_OF_year,
    STROLLERS_HOME_TIPS_FROM_MUMS,
} from 'mk/autogenerated/translations/Home.346b8c3ac8b502f421d1b79e6d6bfa7b'
import { getArticleTitleAsString } from 'mk2/apps/blogs/containers/Article/Article.helpers';
import { articleUrl as blogsArticleUrl } from 'mk2/apps/blogs/utils';
import { counsellingsTopicUrl } from 'mk2/apps/counsellings/urls';
import { forumPostDetailUrl } from 'mk2/apps/forum/urls';
import { BrandCards, BrandCardsPlaceholder } from 'mk2/apps/strollers/components/BrandCards';
import { CollapsedReview } from 'mk2/apps/strollers/components/CollapsedReview';
import { MoreStrollers, MoreStrollersPlaceholder } from 'mk2/apps/strollers/components/MoreStrollers';
import StrollersArticleSectionPills from 'mk2/apps/strollers/components/StrollersArticleSectionPills';
import { StrollerCardPlaceholder } from 'mk2/apps/strollers/components/StrollerCard';
import { RECENT_SECTION } from 'mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSections.constants';
import { Charts } from 'mk2/apps/strollers/containers/Home/Charts';
import { Props } from 'mk2/apps/strollers/containers/Home/HomePage';
import { LinksBox, LinksBoxPlaceholder } from 'mk2/apps/strollers/containers/Home/LinksBox';
import {
    strollersArticlesUrl,
    strollersDetailUrl,
} from 'mk2/apps/strollers/urls';
import ReviewBlogPostPreview, {
    ReviewBlogPostPreviewPlaceholder,
} from 'mk2/apps/wiki/components/ReviewBlogPostPreview';
import { getArticleBadges } from 'mk2/components/Badge';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { LoadingSwitch } from 'mk2/containers/LoadingSwitch/LoadingSwitch';
import { cacheLast } from 'mk2/helpers/cache';
import { timeAt } from 'mk2/helpers/timeformat';
import { url } from 'mk2/helpers/urls';
import { LoadingState } from 'mk2/reducers/loadingState';
import { interpolate } from 'mk2/services/i18n';
import React from 'react';
import styles from './Home.mscss';


export class Home extends React.Component<Props> {
    private articleBadgesCache = cacheLast(true);

    public render() {
        const { brands, loadingState } = this.props;

        return (
            <div className={styles.Home}>
                <div className={styles.Home__cover}>
                    <div className={styles.Home__cover__content}>
                        <h2 className={styles.Home__cover__content__title}>{STROLLER_STROLLEROPEDIA}</h2>
                        <p className={styles.Home__cover__content__description}>{STROLLERS_HOME_COVER_DESCRIPTION}</p>
                    </div>
                </div>

                <LoadingSwitch
                    loadingState={loadingState}
                    onLoad={this.onLoad}
                    onRenderInit={this.renderInit}
                    onRenderSuccess={this.renderLoadSuccess}
                    hasData={brands?.length > 0}
                />
            </div>
        );
    }

    private renderInit = () => {
        // NOTE: Keep in sync with renderSuccess DOM layout-shifts
        return (
            <>
                <LinksBoxPlaceholder className={styles.Home__linksBox} />
                <div className={styles.Home__tips}>
                    <h2 className={styles.Home__tips__title}>{STROLLERS_HOME_TIPS_FROM_MUMS}</h2>
                    <div className={styles.Home__tips__strollers}>
                        {Array.from(Array(6).keys()).map((index) => (
                            <div key={index} className={styles.Home__tips__strollers__stroller}>
                                <StrollerCardPlaceholder />
                            </div>
                        ))}
                    </div>
                    <span className={styles.Home__tips__more}>
                        <LoadingPlaceholder width="140" height="34" />
                    </span>
                </div>
                <div className={styles.Home__articles}>
                    <h2 className={styles.Home__articles__title}>{STROLLEROPEDIA_NEW_ARTICLES_LONG}</h2>
                    <div>
                        {Array.from(Array(3).keys()).map((index) => (
                            <ReviewBlogPostPreviewPlaceholder
                                key={index}
                                className={styles.Home__articles__article}
                                author={false}
                                badges
                            />
                        ))}
                    </div>
                    <Btn
                        className={styles.Home__articles__more}
                        type={BtnType.BlueOutline}
                        label={STROLLEROPEDIA_MORE_ARTICLES}
                        link={strollersArticlesUrl}
                    />
                    <h2 className={styles.Home__articles__title}>{ARTICLE_SECTIONS}</h2>
                    <div className={styles.ArticlesOfSections__sectionFilter}>
                        <StrollersArticleSectionPills />
                    </div>
                </div>
                <div className={styles.Home__thisYearStrollers}>
                    <h2 className={styles.Home__thisYearStrollers__title}>
                        {interpolate(STROLLERS_HOME_STROLLERS_OF_year, { year: 2022 })}
                    </h2>
                    <MoreStrollersPlaceholder count={10} />
                </div>
                <BrandCardsPlaceholder />
                <div className={styles.Home__questions}>
                    <h2 className={styles.Home__questions__title}>{STROLLERS_HOME_NEW_QUESTIONS_IN_COUNSELLING}</h2>
                    <div className={styles.Home__questions__questions}>
                        <div className={styles.Home__questions__questions__question}>
                            <div className={styles.Home__questions__questions__question__title}>
                                <LoadingPlaceholder width={300} />
                            </div>
                            <div className={styles.Home__questions__questions__question__date}>
                                <LoadingPlaceholder width={120} />
                            </div>
                        </div>
                        <div className={styles.Home__questions__questions__question}>
                            <div className={styles.Home__questions__questions__question__title}>
                                <LoadingPlaceholder width={300} />
                            </div>
                            <div className={styles.Home__questions__questions__question__date}>
                                <LoadingPlaceholder width={120} />
                            </div>
                        </div>
                        <div className={styles.Home__questions__questions__question}>
                            <div className={styles.Home__questions__questions__question__title}>
                                <LoadingPlaceholder width={300} />
                            </div>
                            <div className={styles.Home__questions__questions__question__date}>
                                <LoadingPlaceholder width={120} />
                            </div>
                        </div>
                    </div>
                    <Btn
                        className={styles.Home__questions__more}
                        type={BtnType.BlueOutline}
                        label={STROLLERS_HOME_ADD_NEW_QUESTION}
                    />
                </div>
                <div className={styles.Home__reviews}>
                    <h2 className={styles.Home__reviews__title}>{STROLLERS_HOME_NEW_REVIEWS}</h2>
                    <div className={styles.Home__reviews__reviews}>
                        <div className={styles.Home__reviews__reviews__review}>
                            <span className={styles.Home__reviews__reviews__review__stroller}>
                                <LoadingPlaceholder width={250} />
                            </span>
                            <div className={styles.Home__reviews__reviews__review__review}>
                                <LoadingPlaceholder width="100%" height={122} />
                            </div>
                        </div>
                        <div className={styles.Home__reviews__reviews__review}>
                            <span className={styles.Home__reviews__reviews__review__stroller}>
                                <LoadingPlaceholder width={250} />
                            </span>
                            <div className={styles.Home__reviews__reviews__review__review}>
                                <LoadingPlaceholder width="100%" height={122} />
                            </div>
                        </div>
                        <div className={styles.Home__reviews__reviews__review}>
                            <span className={styles.Home__reviews__reviews__review__stroller}>
                                <LoadingPlaceholder width={250} />
                            </span>
                            <div className={styles.Home__reviews__reviews__review__review}>
                                <LoadingPlaceholder width="100%" height={122} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    private renderLoadSuccess = () => {
        const {
            brands,
            chartStrollers,
            chartStrollersLoadingState,
            chartStrollersHasMore,
            onLoadChart,
            onSelectChart,
            recentArticles,
            recentArticlesHasMore,
            recentArticlesLoadingState,
            strollersCountPerBrand,
            reviewsCountPerBrand,
            lastCounsellingsPosts,
            lastReviews,
            lastReviewsStrollersMap,
            selectedChart,
            thisYearStrollers,
            brandsCount,
            buyingGuide,
            strollersPreviewPhotoMap,
            strollersPhotosMap,
        } = this.props;

        const articleBadges = this.articleBadgesCache(() => {
            const dict = {};
            for (const blogPostArticle of recentArticles) {
                dict[blogPostArticle.id] = getArticleBadges(blogPostArticle.hashtagItems).renderedBadges;
            }
            return dict;
        }, [recentArticles]);

        return (
            <>
                <LinksBox buyingGuide={buyingGuide} className={styles.Home__linksBox} />
                <div className={styles.Home__tips}>
                    <h2 className={styles.Home__tips__title}>{STROLLERS_HOME_TIPS_FROM_MUMS}</h2>
                    <Charts
                        strollers={chartStrollers}
                        strollersPreviewPhotoMap={strollersPreviewPhotoMap}
                        strollersPhotosMap={strollersPhotosMap}
                        loadingState={chartStrollersLoadingState}
                        selectedChart={selectedChart}
                        hasMore={chartStrollersHasMore}
                        onLoad={onLoadChart}
                        onSelectChart={onSelectChart}
                        isInComparison={this.isInComparison}
                        onComparisonToggle={this.onComparisonToggle}
                    />
                </div>
                <div className={styles.Home__articles}>
                    <h2 className={styles.Home__articles__title}>{STROLLEROPEDIA_NEW_ARTICLES_LONG}</h2>
                    <div>
                        {recentArticles.map((blogPostArticle, i) => {
                            return (
                                <ReviewBlogPostPreview
                                    key={i}
                                    className={styles.Home__articles__article}
                                    photo={blogPostArticle.photos?.length && blogPostArticle.photos[0]}
                                    title={getArticleTitleAsString(blogPostArticle.titleAST)}
                                    postUrl={blogsArticleUrl(blogPostArticle)}
                                    badges={articleBadges[blogPostArticle.id]}
                                    commentsCount={blogPostArticle.commentsCount}
                                    likersCount={blogPostArticle.likeable.likesCount}
                                />
                            );
                        })}
                    </div>
                    {recentArticlesHasMore && (
                        <Btn
                            className={styles.Home__articles__more}
                            type={BtnType.BlueOutline}
                            label={STROLLEROPEDIA_MORE_ARTICLES}
                            onClick={this.onLoadNextRecentArticles}
                            hasSpinner={recentArticlesLoadingState === LoadingState.LOADING}
                        />
                    )}
                </div>
                <div className={styles.Home__articles}>
                    <h2 className={styles.Home__articles__title}>{ARTICLE_SECTIONS}</h2>
                    <div className={styles.ArticlesOfSections__sectionFilter}>
                        <StrollersArticleSectionPills />
                    </div>
                </div>
                <div className={styles.Home__thisYearStrollers}>
                    <h2 className={styles.Home__thisYearStrollers__title}>
                        {interpolate(STROLLERS_HOME_STROLLERS_OF_year, { year: new Date().getFullYear() })}
                    </h2>
                    <MoreStrollers strollers={thisYearStrollers} strollerPreviewPhotoMap={strollersPreviewPhotoMap} photoObjectsMap={strollersPhotosMap} />
                </div>
                <BrandCards
                    brands={brands}
                    brandsCount={brandsCount}
                    strollersCountPerBrand={strollersCountPerBrand}
                    reviewsCountPerBrand={reviewsCountPerBrand}
                />
                <div className={styles.Home__questions}>
                    <h2 className={styles.Home__questions__title}>{STROLLERS_HOME_NEW_QUESTIONS_IN_COUNSELLING}</h2>
                    <div className={styles.Home__questions__questions}>
                        {lastCounsellingsPosts.map((post) => (
                            <Link
                                key={post.id}
                                to={url(forumPostDetailUrl, { topicSlug: post.topic.slug, postSlug: post.slug })}
                                className={styles.Home__questions__questions__question}
                            >
                                <div className={styles.Home__questions__questions__question__title}>{post.heading}</div>
                                <div className={styles.Home__questions__questions__question__date}>
                                    {timeAt(post.created)}
                                </div>
                            </Link>
                        ))}
                    </div>
                    <Btn
                        className={styles.Home__questions__more}
                        type={BtnType.BlueOutline}
                        label={STROLLERS_HOME_ADD_NEW_QUESTION}
                        link={url(counsellingsTopicUrl, { topicSlug: lastCounsellingsPosts[0].topic.slug })}
                    />
                </div>
                <div className={styles.Home__reviews}>
                    <h2 className={styles.Home__reviews__title}>{STROLLERS_HOME_NEW_REVIEWS}</h2>
                    <div className={styles.Home__reviews__reviews}>
                        {lastReviews.map((review) => (
                            (lastReviewsStrollersMap[review.strollerId] &&
                                <div key={review.id} className={styles.Home__reviews__reviews__review}>
                                <Link
                                    to={url(strollersDetailUrl, { slug: lastReviewsStrollersMap[review.strollerId].slug })}
                                    className={styles.Home__reviews__reviews__review__stroller}
                                >
                                    {lastReviewsStrollersMap[review.strollerId].name}
                                </Link>

                                <CollapsedReview
                                    className={styles.Home__reviews__reviews__review__review}
                                    review={review}
                                    stroller={lastReviewsStrollersMap[review.strollerId]}
                                />
                            </div>)
                        ))}
                    </div>
                </div>
            </>
        );
    };

    private onLoad = () => {
        this.props.onLoad();
        this.props.onStrollersComparisonLoad();
    };

    private onLoadNextRecentArticles = () => {
        const { onLoadNextRecentArticles, recentArticlesAfter } = this.props;
        onLoadNextRecentArticles(RECENT_SECTION, recentArticlesAfter, 10);
    };

    private isInComparison = (strollerId: number) => {
        const { wishlistedStrollers } = this.props;
        return wishlistedStrollers.some((stroller) => stroller.id === strollerId);
    };

    private onComparisonToggle = (strollerId: number) => {
        this.props.onStrollersComparisonToggle(strollerId);
    };
}
