import { STROLLEROPEDIA_NEW_ARTICLES } from 'mk/autogenerated/translations/ArticlesOfSections.constants.ef15754bb4769cbc569544ce779f7a67'
import { BADGE_CONFIGS, BADGE_HASHTAGS } from 'mk2/components/Badge';

export const ARTICLES_OF_SECTIONS_MENU_SECTION_FILTER = 'articles-of-sections-menu-section-filter';

export const RECENT_SECTION = 'recent' as 'recent';

export const SECTIONS_CONFIG = {
    [RECENT_SECTION]: { hashtag: null, label: STROLLEROPEDIA_NEW_ARTICLES, icon: null, sectionSlug: RECENT_SECTION },
    [BADGE_CONFIGS.BuyingGuides.sectionSlug]: { hashtag: BADGE_HASHTAGS.BuyingGuides, ...BADGE_CONFIGS.BuyingGuides},
    [BADGE_CONFIGS.RedactionReview.sectionSlug]: { hashtag: BADGE_HASHTAGS.RedactionReview, ...BADGE_CONFIGS.RedactionReview},
    [BADGE_CONFIGS.HowTo.sectionSlug]: { hashtag: BADGE_HASHTAGS.HowTo, ...BADGE_CONFIGS.HowTo},
    [BADGE_CONFIGS.ExpertTips.sectionSlug]: { hashtag: BADGE_HASHTAGS.ExpertTips, ...BADGE_CONFIGS.ExpertTips},
    [BADGE_CONFIGS.Accessories.sectionSlug]: { hashtag: BADGE_HASHTAGS.Accessories, ...BADGE_CONFIGS.Accessories},
    [BADGE_CONFIGS.News.sectionSlug]: { hashtag: BADGE_HASHTAGS.News, ...BADGE_CONFIGS.News},
};
