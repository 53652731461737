import { strollers_api_v2_get_blog_articles_url } from 'mk/urls/functions';
import {
    strollersArticlesOfSectionsLoadApi,
    StrollersArticlesOfSectionsLoadApiNormalizedResponse,
    StrollersArticlesOfSectionsLoadTriggerAction,
    STROLLERS_ARTICLES_OF_SECTIONS_LOAD_TRIGGER,
} from 'mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSections.actions';
import {
    RECENT_SECTION,
    SECTIONS_CONFIG,
} from 'mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSections.constants';
import { handleXHRGetErrorSaga, normalizeError, XHRAction, XHRGetError } from 'mk2/helpers/api';
import { getLogger } from 'mk2/logger';
import { all, call, put, takeEvery } from 'redux-saga/effects';

const logger = getLogger('strollers/ArticlesOfSections.sagas');

interface LoadApiResponse {
    body: {
        entities: StrollersArticlesOfSectionsLoadApiNormalizedResponse['entities'];
        result: StrollersArticlesOfSectionsLoadApiNormalizedResponse['result'];
        hasMore: boolean;
    };
}

function* load({ sectionSlug, after, limit, xhr }: StrollersArticlesOfSectionsLoadTriggerAction & XHRAction) {
    yield put(strollersArticlesOfSectionsLoadApi.request(sectionSlug, after, limit));

    try {
        const hashtag = sectionSlug === RECENT_SECTION
            ? RECENT_SECTION
            : SECTIONS_CONFIG[sectionSlug].hashtag;
        const response: LoadApiResponse = yield call(() => xhr.get(strollers_api_v2_get_blog_articles_url(hashtag, {
            after,
            limit,
        })));

        yield put(
            strollersArticlesOfSectionsLoadApi.success(sectionSlug, after, limit, response.body.hasMore, {
                entities: response.body.entities,
                result: response.body.result,
            }),
        );
    } catch (error) {
        yield handleXHRGetErrorSaga(error as XHRGetError, logger);
        yield put(strollersArticlesOfSectionsLoadApi.failure(sectionSlug, after, limit, normalizeError(error)));
    }
}

export default function* root() {
    yield all([takeEvery(STROLLERS_ARTICLES_OF_SECTIONS_LOAD_TRIGGER, load)]);
}
