import { faBabyCarriage, faComment } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { StrollerPhoto } from 'mk2/apps/strollers/schemas';
import { ImgCropped, ImgCropMode } from 'mk2/components/ImgCropped';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import React from 'react';
import styles from './BrandCard.mscss';

interface OwnProps {
    className?: string;
    style?: React.CSSProperties;
    photo?: StrollerPhoto;
    name: string;
    strollersCount?: number;
    reviewsCount?: number;
}

type Props = OwnProps;

export const BrandCard: React.FunctionComponent<Props> = React.memo(({
    className, style, photo, name, strollersCount, reviewsCount,
}) => (
    <div className={cx(styles.BrandCard, className)} style={style}>
        {photo && (
            <div className={styles.BrandCard__image}>
                <ImgCropped
                    className={styles.BrandCard__image__img}
                    width={44}
                    height={44}
                    mode={ImgCropMode.Embed}
                    imgWidth={photo.originalWidth}
                    imgHeight={photo.originalHeight}
                    imgUrl={photo.url100x100}
                />
            </div>
        )}
        <div className={styles.BrandCard__info}>
            <div className={styles.BrandCard__info__name}>{name}</div>
            <div className={styles.BrandCard__info__stats}>
                {strollersCount > 0 && (
                    <>
                        <FontAwesomeIcon icon={faBabyCarriage} className={styles.BrandCard__info__stats__icon} />{' '}
                        <span className={styles.BrandCard__info__stats__count}>{strollersCount}</span>{' '}
                    </>
                )}
                {reviewsCount > 0 && (
                    <>
                        <FontAwesomeIcon icon={faComment} className={styles.BrandCard__info__stats__icon} />{' '}
                        <span className={styles.BrandCard__info__stats__count}>{reviewsCount}</span>
                    </>
                )}
            </div>
        </div>
    </div>
));

BrandCard.displayName = 'BrandCard';

interface PlaceholderProps {
    className?: string;
    style?: React.CSSProperties;
}

export const BrandCardPlaceholder: React.FunctionComponent<PlaceholderProps> = ({ className, style }) => (
    <div className={cx(styles.BrandCard, className)} style={style}>
        <div className={styles.BrandCard__image}>
            <span className={styles.BrandCard__image__img}>
                <LoadingPlaceholder width={44} height={44} />
            </span>
        </div>
        <div className={styles.BrandCard__info}>
            <div className={styles.BrandCard__info__name}>
                <LoadingPlaceholder width={80} />
            </div>
            <div className={styles.BrandCard__info__stats}>
                <FontAwesomeIcon icon={faBabyCarriage} className={styles.BrandCard__info__stats__icon} />{' '}
                <span className={styles.BrandCard__info__stats__count}>
                    <LoadingPlaceholder width={12} />
                </span>{' '}
                <FontAwesomeIcon icon={faComment} className={styles.BrandCard__info__stats__icon} />{' '}
                <span className={styles.BrandCard__info__stats__count}>
                    <LoadingPlaceholder width={12} />
                </span>
            </div>
        </div>
    </div>
);

BrandCardPlaceholder.displayName = 'BrandCardPlaceholder';
