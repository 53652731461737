import uniq from 'lodash-es/uniq';
import {
    ChartType,
    StrollersHomeLoadApiFailureAction,
    StrollersHomeLoadApiRequestAction,
    StrollersHomeLoadApiSuccessAction,
    StrollersHomeLoadChartApiFailureAction,
    StrollersHomeLoadChartApiRequestAction,
    StrollersHomeLoadChartApiSuccessAction,
    StrollersHomeSelectChartAction,
    STROLLERS_HOME_LOAD_API_FAILURE,
    STROLLERS_HOME_LOAD_API_REQUEST,
    STROLLERS_HOME_LOAD_API_SUCCESS,
    STROLLERS_HOME_LOAD_CHART_API_FAILURE,
    STROLLERS_HOME_LOAD_CHART_API_REQUEST,
    STROLLERS_HOME_LOAD_CHART_API_SUCCESS,
    STROLLERS_HOME_SELECT_CHART,
} from 'mk2/apps/strollers/containers/Home/Home.actions';
import { tupdate } from 'mk2/helpers/types';
import { errorMessage } from 'mk2/reducers/errorMessage';
import { loadingState, LoadingState } from 'mk2/reducers/loadingState';
import { Reducer } from 'redux';

type HomeActions =
    | StrollersHomeLoadApiRequestAction
    | StrollersHomeLoadApiSuccessAction
    | StrollersHomeLoadApiFailureAction
    | StrollersHomeSelectChartAction;

type HomeChartActions =
    | StrollersHomeLoadChartApiRequestAction
    | StrollersHomeLoadChartApiSuccessAction
    | StrollersHomeLoadChartApiFailureAction;

interface ChartState {
    strollerIds: number[];
    loadingState: LoadingState;
    errorMessage: string;
    hasMore: boolean;
}

const initialChartState: ChartState = {
    strollerIds: [],
    loadingState: LoadingState.INIT,
    errorMessage: null,
    hasMore: true,
};

const chartReducer: Reducer<ChartState, HomeChartActions> = (state = initialChartState, action) => {
    switch (action.type) {
        case STROLLERS_HOME_LOAD_CHART_API_REQUEST:
            return {
                ...state,
                loadingState: loadingState(state.loadingState, action),
            };
        case STROLLERS_HOME_LOAD_CHART_API_SUCCESS: {
            const index = state.strollerIds[(action.offset ?? 1) - 1] || -1;
            const result = action.response.result.strollers;
            const ids = uniq(index === -1 ? result : [...state.strollerIds.slice(0, index + 1), ...result]);
            return {
                ...state,
                loadingState: loadingState(state.loadingState, action),
                strollerIds: ids,
                hasMore: action.hasMore,
            };
        }
        case STROLLERS_HOME_LOAD_CHART_API_FAILURE:
            return {
                ...state,
                loadingState: loadingState(state.loadingState, action),
                errorMessage: errorMessage(state.errorMessage, action),
            };
        default:
            return state;
    }
};

export interface State {
    brandIds: number[];
    guideArticlesIds: number[];
    brandsCount: number;
    strollersCountPerBrand: Record<number, number>;
    reviewsCountPerBrand: Record<number, number>;
    lastReviewsIds: number[];
    lastReviewsStrollersIds: number[];
    lastCounsellingsPostsIds: number[];
    wikiArticlesIds: number[];
    loadingState: LoadingState;
    errorMessage: string;
    charts: Record<ChartType, ChartState>;
    selectedChart: ChartType;
    thisYearStrollerIds: number[];
    buyingGuideId: number;
    strollersPreviewPhotoMap: Record<number, number>;
    strollersPhotosIds: number[];
}

export const initialState: State = {
    brandIds: [],
    guideArticlesIds: [],
    brandsCount: 0,
    strollersCountPerBrand: {},
    reviewsCountPerBrand: {},
    lastReviewsIds: [],
    lastReviewsStrollersIds: [],
    lastCounsellingsPostsIds: [],
    wikiArticlesIds: [],
    loadingState: LoadingState.INIT,
    errorMessage: null,
    charts: {
        top: initialChartState,
        happyMedium: initialChartState,
        popular: initialChartState,
        score: initialChartState,
        inBazaar: initialChartState,
    },
    selectedChart: 'top',
    thisYearStrollerIds: [],
    buyingGuideId: null,
    strollersPreviewPhotoMap: {},
    strollersPhotosIds: [],
};

const reducer: Reducer<State, HomeActions | HomeChartActions> = (state = initialState, action) => {
    switch (action.type) {
        case STROLLERS_HOME_LOAD_API_REQUEST:
        case STROLLERS_HOME_LOAD_API_FAILURE:
            return {
                ...state,
                loadingState: loadingState(state.loadingState, action),
                errorMessage: errorMessage(state.errorMessage, action),
            };
        case STROLLERS_HOME_LOAD_API_SUCCESS:
            return {
                ...state,
                brandIds: action.response.result.brands,
                guideArticlesIds: action.response.result.guideArticles,
                lastReviewsIds: action.response.result.lastReviews,
                lastReviewsStrollersIds: action.response.result.lastReviewsStrollers,
                lastCounsellingsPostsIds: action.response.result.lastCounsellingsPosts,
                wikiArticlesIds: action.response.result.wikiArticles,
                strollersCountPerBrand: action.strollersCountPerBrand,
                reviewsCountPerBrand: action.reviewsCountPerBrand,
                loadingState: loadingState(state.loadingState, action),
                errorMessage: errorMessage(state.errorMessage, action),
                brandsCount: action.brandsCount,
                charts: {
                    ...state.charts,
                    top: {
                        ...state.charts.top,
                        loadingState: LoadingState.SUCCESS,
                        strollerIds: action.response.result.topStrollers,
                    },
                },
                thisYearStrollerIds: action.response.result.thisYearStrollers,
                buyingGuideId: action.response.result.buyingGuide,
                strollersPreviewPhotoMap: tupdate(state.strollersPreviewPhotoMap, action.response.result.strollersPreviewPhotoMap),
                strollersPhotosIds: uniq([...state.strollersPhotosIds, ...action.response.result.strollersPhotos]),
            };
        case STROLLERS_HOME_LOAD_CHART_API_REQUEST:
        case STROLLERS_HOME_LOAD_CHART_API_SUCCESS:
        case STROLLERS_HOME_LOAD_CHART_API_FAILURE:
            return {
                ...state,
                charts: {
                    ...state.charts,
                    [action.chartType]: chartReducer(state.charts[action.chartType], action),
                },
                strollersPreviewPhotoMap: tupdate(state.strollersPreviewPhotoMap, 'response' in action ? action.response.result.strollersPreviewPhotoMap : {}),
                strollersPhotosIds: uniq([...state.strollersPhotosIds, ...'response' in action ? action.response.result.strollersPhotos : []]),
            };
        case STROLLERS_HOME_SELECT_CHART:
            return {
                ...state,
                selectedChart: action.chartType,
            };
        default:
            return state;
    }
};

export default reducer;
