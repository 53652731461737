import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { SECTIONS_CONFIG } from 'mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSections.constants';
import { strollersArticlesOfSectionUrl } from 'mk2/apps/strollers/urls';
import { Link } from 'mk2/components/Link';
import { url } from 'mk2/helpers/urls';
import React from 'react';
import styles from './StrollersArticleSectionPills.mscss';

interface OwnProps {
    className?: string;
    currentSectionSlug?: string;
}

export type Props = OwnProps;

const StrollersArticleSectionPills: React.FunctionComponent<Props> = ({
    className,
    currentSectionSlug,
}) => (
    <div className={cx(styles.StrollersArticleSectionPills, className)}>
        {Object.values(SECTIONS_CONFIG).map((section) => (
            <Link
                key={section.sectionSlug}
                className={cx(
                    styles.StrollersArticleSectionPills__pill,
                    currentSectionSlug && currentSectionSlug === section.sectionSlug
                        ? styles.StrollersArticleSectionPills__pill__selected
                        : styles.StrollersArticleSectionPills__pill__default,
                )}
                to={url(strollersArticlesOfSectionUrl, { sectionSlug: section.sectionSlug })}
            >
                {section.icon && (
                    <div className={styles.StrollersArticleSectionPills__pill__icon}>
                        <FontAwesomeIcon icon={section.icon} />
                    </div>
                )}
                <div className={styles.StrollersArticleSectionPills__pill__label}>
                    {section.label}
                </div>
            </Link>
        ))}
    </div>
);

StrollersArticleSectionPills.displayName = 'StrollersArticleSectionPills';

export default React.memo<Props>(StrollersArticleSectionPills);
