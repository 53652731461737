import cx from 'classnames';
import {
    STROLLERS_HOME_TIPS_FROM_MUMS_MORE_BY_IN_BAZAAR,
    STROLLERS_HOME_TIPS_FROM_MUMS_MORE_BY_SCORE,
    STROLLERS_HOME_TIPS_FROM_MUMS_MORE_HAPPY_MEDIUM,
    STROLLERS_HOME_TIPS_FROM_MUMS_MORE_POPULAR,
    STROLLERS_HOME_TIPS_FROM_MUMS_MORE_TOP,
    STROLLERS_HOME_TIPS_FROM_MUMS_TAB_HAPPY_MEDIUM,
    STROLLERS_HOME_TIPS_FROM_MUMS_TAB_IN_BAZAAR,
    STROLLERS_HOME_TIPS_FROM_MUMS_TAB_POPULAR,
    STROLLERS_HOME_TIPS_FROM_MUMS_TAB_SCORE,
    STROLLERS_HOME_TIPS_FROM_MUMS_TAB_TOP,
} from 'mk/autogenerated/translations/Charts.45a89f79afa320cbc321a7c258105f45'
import { StrollerCard, StrollerCardPlaceholder } from 'mk2/apps/strollers/components/StrollerCard';
import { ChartType } from 'mk2/apps/strollers/containers/Home/Home.actions';
import type { StrollerEntity, StrollerPhoto } from 'mk2/apps/strollers/schemas';
import { Btn, BtnType } from 'mk2/components/Btn';
import { LoadingSwitch } from 'mk2/containers/LoadingSwitch/LoadingSwitch';
import { LoadingState } from 'mk2/reducers/loadingState';
import React, { useCallback, useEffect, useRef } from 'react';
import styles from './Charts.mscss';

const chartLoadMoreButtonLabels: Record<ChartType, string> = {
    top: STROLLERS_HOME_TIPS_FROM_MUMS_MORE_TOP,
    happyMedium: STROLLERS_HOME_TIPS_FROM_MUMS_MORE_HAPPY_MEDIUM,
    popular: STROLLERS_HOME_TIPS_FROM_MUMS_MORE_POPULAR,
    score: STROLLERS_HOME_TIPS_FROM_MUMS_MORE_BY_SCORE,
    inBazaar: STROLLERS_HOME_TIPS_FROM_MUMS_MORE_BY_IN_BAZAAR,
};

interface OwnProps {
    className?: string;
    strollers: StrollerEntity[];
    strollersPreviewPhotoMap: Record<number, number>;
    strollersPhotosMap: Record<number, StrollerPhoto>;
    selectedChart: ChartType;
    loadingState: LoadingState;
    hasMore: boolean;

    onLoad(chartType: ChartType, offset: number);
    onSelectChart(chartType: ChartType);
    isInComparison(strollerId: number): boolean;
    onComparisonToggle(strollerId: number);
}

type Props = OwnProps;

export const Charts: React.FunctionComponent<Props> = ({
    className,
    strollers,
    strollersPreviewPhotoMap,
    strollersPhotosMap,
    selectedChart,
    loadingState,
    hasMore,
    onLoad,
    onSelectChart,
    isInComparison,
    onComparisonToggle,
}) => {
    const onLoadCallback = useCallback(() => {
        onLoad(selectedChart, null);
    }, [onLoad, selectedChart]);

    const loadedStrollersCount = strollers?.length ?? 0;
    const onLoadMoreCallback = useCallback(() => {
        if (loadingState === LoadingState.LOADING) {
            return;
        }
        onLoad(selectedChart, loadedStrollersCount);
    }, [onLoad, selectedChart, loadedStrollersCount, loadingState]);

    const onSelectTopChart = useCallback(() => {
        onSelectChart('top');
    }, [onSelectChart]);

    const onSelectHappyMediumChart = useCallback(() => {
        onSelectChart('happyMedium');
    }, [onSelectChart]);

    const onSelectPopularChart = useCallback(() => {
        onSelectChart('popular');
    }, [onSelectChart]);

    const onSelectScoreChart = useCallback(() => {
        onSelectChart('score');
    }, [onSelectChart]);

    const onSelectInBazaarChart = useCallback(() => {
        onSelectChart('inBazaar');
    }, [onSelectChart]);

    const tabsContainer = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!tabsContainer.current) {
            return;
        }

        const width = tabsContainer.current.clientWidth;
        const scrollLeft = tabsContainer.current.scrollLeft;
        const selected = tabsContainer.current.querySelector(`.${styles['Charts__tabs__tab--selected']}`);
        let sum = 0;
        for (const tab of Array.from(tabsContainer.current?.children)) {
            const start = sum;
            sum += tab.clientWidth;
            const stop = sum;
            if (tab === selected) {
                if (start < scrollLeft || stop > scrollLeft + width) {
                    tabsContainer.current.scrollLeft = Math.max(sum - width, 0);
                }
                break;
            }
        }
    }, [selectedChart]);

    const onRenderInit = useCallback(
        () => (
            <>
                <div className={styles.Charts__strollers}>
                    {Array.from(Array(6).keys()).map((index) => (
                        <div key={index} className={styles.Charts__strollers__stroller}>
                            <StrollerCardPlaceholder />
                        </div>
                    ))}
                </div>
            </>
        ),
        [],
    );

    const onRenderSuccess = useCallback(
        () => (
            <>
                <div className={styles.Charts__strollers}>
                    {strollers.map((stroller) => (
                        <StrollerCard
                            key={stroller.id}
                            className={styles.Charts__strollers__stroller}
                            stroller={stroller}
                            strollerPhoto={stroller.id in strollersPreviewPhotoMap && strollersPreviewPhotoMap[stroller.id]
                                            in strollersPhotosMap ? strollersPhotosMap[strollersPreviewPhotoMap[stroller.id]] : null}
                            isInComparison={isInComparison(stroller.id)}
                            onComparisonToggle={onComparisonToggle}
                        />
                    ))}
                </div>
                {hasMore && loadedStrollersCount > 0 && (
                    <Btn
                        className={styles.Charts__more}
                        type={BtnType.BlueOutline}
                        label={chartLoadMoreButtonLabels[selectedChart]}
                        onClick={onLoadMoreCallback}
                        hasSpinner={loadingState === LoadingState.LOADING}
                        disabled={loadingState === LoadingState.LOADING}
                    />
                )}
            </>
        ),
        [strollers, hasMore, loadingState, loadedStrollersCount, isInComparison, onComparisonToggle],
    );

    return (
        <div className={cx(styles.Charts, className)}>
            <div className={styles.Charts__tabs} ref={tabsContainer}>
                <a
                    className={cx(
                        styles.Charts__tabs__tab,
                        selectedChart === 'top' && styles['Charts__tabs__tab--selected'],
                    )}
                    onClick={onSelectTopChart}
                >
                    {STROLLERS_HOME_TIPS_FROM_MUMS_TAB_TOP}
                </a>
                <a
                    className={cx(
                        styles.Charts__tabs__tab,
                        selectedChart === 'happyMedium' && styles['Charts__tabs__tab--selected'],
                    )}
                    onClick={onSelectHappyMediumChart}
                >
                    {STROLLERS_HOME_TIPS_FROM_MUMS_TAB_HAPPY_MEDIUM}
                </a>
                <a
                    className={cx(
                        styles.Charts__tabs__tab,
                        selectedChart === 'popular' && styles['Charts__tabs__tab--selected'],
                    )}
                    onClick={onSelectPopularChart}
                >
                    {STROLLERS_HOME_TIPS_FROM_MUMS_TAB_POPULAR}
                </a>
                <a
                    className={cx(
                        styles.Charts__tabs__tab,
                        selectedChart === 'score' && styles['Charts__tabs__tab--selected'],
                    )}
                    onClick={onSelectScoreChart}
                >
                    {STROLLERS_HOME_TIPS_FROM_MUMS_TAB_SCORE}
                </a>
                <a
                    className={cx(
                        styles.Charts__tabs__tab,
                        selectedChart === 'inBazaar' && styles['Charts__tabs__tab--selected'],
                    )}
                    onClick={onSelectInBazaarChart}
                >
                    {STROLLERS_HOME_TIPS_FROM_MUMS_TAB_IN_BAZAAR}
                </a>
                <div className={styles.Charts__tabs__rest} />
            </div>
            <LoadingSwitch
                loadingState={loadingState}
                onLoad={onLoadCallback}
                onRenderInit={onRenderInit}
                onRenderSuccess={onRenderSuccess}
                hasData={strollers?.length > 0}
            />
        </div>
    );
};

Charts.displayName = 'Charts';
