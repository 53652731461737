import { TAB_BLOGS_ALL, TAB_BLOGS_POPULAR, TAB_BLOGS_SUBSCRIBED } from 'mk/autogenerated/translations/utils.537624932b09b21b67e48b7e56e1fed6'
import { isAuthenticated } from 'mk/bazaar/common/userUtils';
import { ACTIVE_SERVER_ID, FACEBOOK_APP_ID } from 'mk/settings';
import { convertFeedsTabsLastVisitCounts } from 'mk2/apps/blogs/helpers';
import {
    blogsAlbumPhotoUrl,
    blogsArticleUrl,
    blogsAutoMessagePhotoUrl,
    blogsFriendsFeedUrl,
    blogsPopularFeedUrl,
    blogsShortMessagePhotoUrl,
    blogsUrl,
} from 'mk2/apps/blogs/urls';
import { groupsGroupUrl, groupsPhotoDetailUrl } from 'mk2/apps/groups/urls';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Count } from 'mk2/components/NotificationsBadge';
import { getAllPlaintext } from 'mk2/helpers/article_ast/utils';
import { url } from 'mk2/helpers/urls';
import { BlogPostEntity, BlogPostType, ImageEntity, PhotoEntity, UserEntity } from 'mk2/schemas';
import React from 'react';

export const getModelAndId = (key: string): string[] => key.split('/');

export const canCreateBlogContests = (user: UserEntity) => {
    return !!user.isAmbassadorOfBrandId || user.username === 'redakce' || user.username === 'redakcia';
};

export function articleUrl(article: BlogPostEntity): string {
    return url(blogsArticleUrl, {username: article.user.username, articleSlug: article.slug});
}

export function largestAvailablePhotoVersionOfBlogPost(photo: PhotoEntity, wishedVersion: 's720x720' | 's1600x1600') {
    return wishedVersion === 's1600x1600' ? 's1600x1600' : 's720x720';
}

export const canBeSetSticky = (post: BlogPostEntity): boolean => {
    return !!getAllPlaintext(post.perexAST);
};

export const getShortMessageHashtagLink = (hashtag: string): string => {
    return url(blogsUrl, {}, { h: hashtag, source: 'message' });
};

export const getBlogsFeedUrlWithHashtagLink = (hashtag: string, source?: string): string => {
    return url(blogsUrl, {}, { query: `#${hashtag}`, source: source || 'message' });
};

export const getGroupHashtagLink = (groupId: number, hashtag: string): string => {
    return url(groupsGroupUrl, { groupId }, { query: `#${hashtag}` });
};

export function initFacebookSdk() {
    // Prosim snazte sa reusovat tuto funkciu, nerobte vlastne kopie.
    // FB-api treba pravidelne updatovat, lebo ho pravidelne deprecatuju.
    // Tak nech sa to da robit na jednom mieste.
    const fbUrl = {
        201: 'https://connect.facebook.net/sk_SK/sdk.js',
        202: 'https://connect.facebook.net/cs_CZ/sdk.js',
    }[ACTIVE_SERVER_ID];
    // const fbUrl = 'https://connect.facebook.net/en_US/sdk/debug.js';

    // https://developers.facebook.com/docs/javascript/quickstart
    // tslint:disable
    const win: any = window;
    if (!win.FB) {
        win.fbAsyncInit = () => {
            win.FB.init({
                appId: FACEBOOK_APP_ID,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v8.0',
            });
        };

        // Load Facebook SDK for JavaScript
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            if (!fjs) return;
            js = d.createElement(s); js.id = id;
            js.src = fbUrl;
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }
    // tslint:enable
}

export function destroyFacebookSdk() {
    const win: any = window;
    win.FB = null;
    document.getElementById('facebook-jssdk').remove();
}

export function photoDetailUrl(post: BlogPostEntity, image: ImageEntity): string {
    return post.group
        ? url(groupsPhotoDetailUrl, { groupId: post.group.id, postSlug: post.slug, imageId: image.id })
        : post.type === BlogPostType.ALBUM
            ? url(blogsAlbumPhotoUrl, { username: post.author.username, postSlug: post.slug, imageId: image.id })
            : post.type === BlogPostType.AUTOMATIC_MESSAGE
                ? url(blogsAutoMessagePhotoUrl, { username: post.author.username, postSlug: post.slug, imageId: image.id })
                : url(blogsShortMessagePhotoUrl, { username: post.author.username, postSlug: post.slug, imageId: image.id });

}

export function blogFeedHeaderTabs(active: string, requestUser: UserEntity, feedsTabsLastVisitCounts: {[tabName: string]: Count}) {

    const {all, friends, popular} = convertFeedsTabsLastVisitCounts(feedsTabsLastVisitCounts);

    const tabSubscribed = (
        <Btn
            key="friends"
            type={BtnType.Tab}
            label={TAB_BLOGS_SUBSCRIBED}
            link={blogsFriendsFeedUrl}
            count={friends}
            active={active === 'friends'}
        />
    );
    const tabPopular = (
        <Btn
            key="popular"
            type={BtnType.Tab}
            label={TAB_BLOGS_POPULAR}
            link={blogsPopularFeedUrl}
            count={popular}
            active={active === 'popular'}
        />
    );
    const tabAll = (
        <Btn
            key="all"
            type={BtnType.Tab}
            label={TAB_BLOGS_ALL}
            link={blogsUrl}
            count={all}
            active={active === 'all'}
        />
    );

    if (requestUser && isAuthenticated(requestUser)) {
        return [tabSubscribed, tabPopular, tabAll];
    } else {
        return [tabPopular, tabAll];
    }
}

export function userHasAdminPerm(inBlogs: boolean, requestUserPermissions: string[]) {
    // keep aligned with user_has_admin_perm(..)
    return inBlogs
        ? requestUserPermissions.indexOf('photoblog.can_manage_blogs') >= 0
        : requestUserPermissions.indexOf('forum.can_manage_forum') >= 0; // can_manage_forum empower also in groups
}
