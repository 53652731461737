import uniq from 'lodash-es/uniq';
import {
    StrollersComparisonLoadApiFailureAction,
    StrollersComparisonLoadApiRequestAction,
    StrollersComparisonLoadApiSuccessAction,
    StrollersComparisonRemoveAllApiFailureAction,
    StrollersComparisonRemoveAllApiRequestAction,
    StrollersComparisonRemoveAllApiSuccessAction,
    StrollersComparisonStoreOrderingApiFailureAction,
    StrollersComparisonStoreOrderingApiRequestAction,
    StrollersComparisonStoreOrderingApiSuccessAction,
    StrollersComparisonToggleApiSuccessAction,
    STROLLERS_COMPARISON_LOAD_API_FAILURE,
    STROLLERS_COMPARISON_LOAD_API_REQUEST,
    STROLLERS_COMPARISON_LOAD_API_SUCCESS,
    STROLLERS_COMPARISON_REMOVE_ALL_API_FAILURE,
    STROLLERS_COMPARISON_REMOVE_ALL_API_REQUEST,
    STROLLERS_COMPARISON_REMOVE_ALL_API_SUCCESS,
    STROLLERS_COMPARISON_STORE_ORDERING_API_FAILURE,
    STROLLERS_COMPARISON_STORE_ORDERING_API_REQUEST,
    STROLLERS_COMPARISON_STORE_ORDERING_API_SUCCESS,
    STROLLERS_COMPARISON_TOGGLE_API_SUCCESS,
} from 'mk2/apps/strollers/containers/StrollersComparison/StrollersComparison.actions';
import { tupdate } from 'mk2/helpers/types';
import { errorMessage } from 'mk2/reducers/errorMessage';
import { loadingState, LoadingState } from 'mk2/reducers/loadingState';
import { Reducer } from 'redux';

export interface State {
    strollersIds: number[];
    strollersPreviewPhotoMap: Record<number, number>;
    strollersPhotosIds: number[];
    allIds: number[];
    loadingState: LoadingState;
    errorMessage: string;
    count: number;
}

export const initialState: State = {
    strollersIds: null,
    strollersPreviewPhotoMap: {},
    strollersPhotosIds: [],
    allIds: [],
    loadingState: LoadingState.INIT,
    errorMessage: null,
    count: 0,
};

type StrollersComparisonActions =
    | StrollersComparisonLoadApiRequestAction
    | StrollersComparisonLoadApiSuccessAction
    | StrollersComparisonLoadApiFailureAction
    | StrollersComparisonToggleApiSuccessAction
    | StrollersComparisonRemoveAllApiFailureAction
    | StrollersComparisonRemoveAllApiRequestAction
    | StrollersComparisonRemoveAllApiSuccessAction
    | StrollersComparisonStoreOrderingApiFailureAction
    | StrollersComparisonStoreOrderingApiRequestAction
    | StrollersComparisonStoreOrderingApiSuccessAction;

const reducer: Reducer<State, StrollersComparisonActions> = (state = initialState, action) => {
    switch (action.type) {
        case STROLLERS_COMPARISON_LOAD_API_REQUEST:
        case STROLLERS_COMPARISON_LOAD_API_FAILURE:
        case STROLLERS_COMPARISON_STORE_ORDERING_API_REQUEST:
        case STROLLERS_COMPARISON_STORE_ORDERING_API_FAILURE:
        case STROLLERS_COMPARISON_REMOVE_ALL_API_REQUEST:
        case STROLLERS_COMPARISON_REMOVE_ALL_API_FAILURE:
            return {
                ...state,
                loadingState: loadingState(state.loadingState, action),
                errorMessage: errorMessage(state.errorMessage, action),
            };

        case STROLLERS_COMPARISON_REMOVE_ALL_API_SUCCESS:
            return {
                ...state,
                strollersIds: [],
                strollersPreviewPhotoMap: {},
                strollersPhotosIds: [],
                count: 0,
                allIds: [],
                loadingState: loadingState(state.loadingState, action),
                errorMessage: errorMessage(state.errorMessage, action),
            };

        case STROLLERS_COMPARISON_LOAD_API_SUCCESS:
            const result = action.response.result.strollers;

            return {
                ...state,
                strollersIds: uniq(result),
                strollersPreviewPhotoMap: tupdate(state.strollersPreviewPhotoMap, action.response.result.strollersPreviewPhotoMap),
                strollersPhotosIds: uniq([...state.strollersPhotosIds, ...action.response.result.strollersPhotos]),
                count: action.count,
                allIds: action.ids,
                loadingState: loadingState(state.loadingState, action),
                errorMessage: errorMessage(state.errorMessage, action),
            };

        case STROLLERS_COMPARISON_STORE_ORDERING_API_SUCCESS:
            return {
                ...state,
                strollersIds: action.orderedIds,
                count: action.orderedIds.length,
                allIds: action.orderedIds,
                loadingState: loadingState(state.loadingState, action),
                errorMessage: errorMessage(state.errorMessage, action),
            };

        case STROLLERS_COMPARISON_TOGGLE_API_SUCCESS:
            return {
                ...state,
                strollersIds: action.added
                    ? [action.id, ...state.strollersIds]
                    : state.strollersIds.filter((i) => i !== action.id),
                count: action.count,
                allIds: action.ids,
            };

        default:
            return state;
    }
};

export default reducer;
