import { faBalanceScaleLeft, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { SEO_TITLE_STROLLERS, STROLLER_SEO_GENERAL_DESC } from 'mk/autogenerated/translations/HomePage.624e81016a76c3229266bb59e8ff521d'
import { PostEntity, PostSchema } from 'mk2/apps/forum/schemas';
import {
    strollersArticlesOfSectionsLoadTrigger,
} from 'mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSections.actions';
import { RECENT_SECTION } from 'mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSections.constants';
import strollersArticlesReducer from 'mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSections.reducers';
import strollersArticlesSaga from 'mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSections.sagas';
import {
    getStrollerArticlesOfSectionState,
} from 'mk2/apps/strollers/containers/ArticlesOfSections/ArticlesOfSections.selectors';
import { Home } from 'mk2/apps/strollers/containers/Home/Home';
import {
    strollersHomeLoad,
    strollersHomeLoadChart,
    strollersHomeSelectChart,
    ChartType,
} from 'mk2/apps/strollers/containers/Home/Home.actions';
import reducer from 'mk2/apps/strollers/containers/Home/Home.reducers';
import saga from 'mk2/apps/strollers/containers/Home/Home.sagas';
import { getStrollersHomeState } from 'mk2/apps/strollers/containers/Home/Home.selectors';
import {
    strollersComparisonLoad,
    strollersComparisonToggle,
} from 'mk2/apps/strollers/containers/StrollersComparison/StrollersComparison.actions';
import strollersComparisonReducer from 'mk2/apps/strollers/containers/StrollersComparison/StrollersComparison.reducers';
import strollersComparisonSaga from 'mk2/apps/strollers/containers/StrollersComparison/StrollersComparison.sagas';
import {
    getStrollersComparisonState,
} from 'mk2/apps/strollers/containers/StrollersComparison/StrollersComparison.selectors';
import { PageMode } from 'mk2/apps/strollers/containers/StrollersSearch/StrollersSearch.constants';
import { breadcrumbsStrollers } from 'mk2/apps/strollers/structuredData';
import { strollersComparisonUrl, strollersSearchUrl } from 'mk2/apps/strollers/urls';
import { Btn, BtnCountType, BtnLayout, BtnType } from 'mk2/components/Btn';
import { MapDispatchToPropsObject } from 'mk2/helpers/types';
import { url } from 'mk2/helpers/urls';
import Page, { HalfpageProps } from 'mk2/pages/Page';
import { getRequestBaseUrl, getRequestUser, AppState } from 'mk2/reducers';
import { LoadingState } from 'mk2/reducers/loadingState';
import {
    BlogPostEntity,
    BlogPostSchema,
    ContentItemEntity,
    ContentItemSchema, PhotoDetailSchema,
    StrollerBrandEntity,
    StrollerBrandSchema,
    StrollerEntity, StrollerPhoto,
    StrollerSchema,
    StrollerUserReviewEntity,
    StrollerUserReviewSchema,
    UserEntity,
} from 'mk2/schemas';
import { getDenormalizedEntities, getDenormalizedEntity } from 'mk2/selectors';
import { Store } from 'mk2/store/configureStore';
import React from 'react';
import { MapStateToPropsParam } from 'react-redux';
import { RouteComponentProps } from 'react-router';

interface OwnProps {}

interface StateProps {
    brands: StrollerBrandEntity[];
    wishlistedStrollers: StrollerEntity[];
    lastCounsellingsPosts: PostEntity[];
    lastReviews: StrollerUserReviewEntity[];
    lastReviewsStrollersMap: Record<number, StrollerEntity>;
    loadingState: LoadingState;
    errorMessage: string;
    comparison: number[];
    strollersCountPerBrand: Record<number, number>;
    reviewsCountPerBrand: Record<number, number>;
    requestUser: UserEntity;
    baseUrl: string;

    recentArticles: BlogPostEntity[];
    recentArticlesAfter: number;
    recentArticlesHasMore: boolean;
    recentArticlesLoadingState: LoadingState;

    chartStrollers: StrollerEntity[];
    chartStrollersHasMore: boolean;
    chartStrollersLoadingState: LoadingState;
    selectedChart: ChartType;

    thisYearStrollers: StrollerEntity[];
    brandsCount: number;
    buyingGuide: ContentItemEntity;

    strollersPreviewPhotoMap: Record<number, number>;
    strollersPhotosMap: Record<number, StrollerPhoto>;
}

interface DispatchProps {
    onLoad(after?: string);
    onLoadChart(chartType: ChartType, offset: number);
    onSelectChart(chartType: ChartType);
    onStrollersComparisonLoad();
    onStrollersComparisonToggle(strollerId: number);
    onLoadNextRecentArticles(section: string, after?: number, limit?: number);
}

type RouteProps = RouteComponentProps<{}>;

export type Props = OwnProps & StateProps & DispatchProps & RouteProps;

export default class HomePage extends Page<OwnProps & RouteProps, StateProps, DispatchProps> {
    public mapStateToProps(): MapStateToPropsParam<StateProps, OwnProps & RouteProps, AppState> {
        return (state: AppState, ownProps: OwnProps & RouteProps): StateProps => {
            const homeState = getStrollersHomeState(state);
            const strollersComparisonState = getStrollersComparisonState(state);

            const selectedChart = homeState.selectedChart;
            const chartStrollersState = homeState.charts[selectedChart];
            const recentArticlesState = getStrollerArticlesOfSectionState(state, RECENT_SECTION);
            const lastReviewsStrollers = getDenormalizedEntities<StrollerEntity>(
                    state,
                    StrollerSchema,
                    homeState.lastReviewsStrollersIds,
                );
            const lastReviewsStrollersMap = {};
            lastReviewsStrollers.map(stroller => lastReviewsStrollersMap[stroller.id] = stroller);

            const strollersPreviewPhotoMap = homeState.strollersPreviewPhotoMap;
            const strollersPhotos = getDenormalizedEntities<StrollerPhoto>(state, PhotoDetailSchema, homeState.strollersPhotosIds);
            const strollersPhotosMap = {};
            strollersPhotos.map(strollerPhoto => strollersPhotosMap[strollerPhoto.id] = strollerPhoto);

            return {
                errorMessage: homeState.errorMessage,
                loadingState: homeState.loadingState,
                brands: getDenormalizedEntities<StrollerBrandEntity>(state, StrollerBrandSchema, homeState.brandIds),
                comparison: strollersComparisonState.allIds,

                recentArticles: getDenormalizedEntities<BlogPostEntity>(state, BlogPostSchema, recentArticlesState.articleIds),
                recentArticlesAfter: recentArticlesState.hasMore
                    ? recentArticlesState.articleIds[recentArticlesState.articleIds.length - 1] : null,
                recentArticlesHasMore: recentArticlesState.hasMore,
                recentArticlesLoadingState: recentArticlesState.loadingState,

                strollersCountPerBrand: homeState.strollersCountPerBrand,
                reviewsCountPerBrand: homeState.reviewsCountPerBrand,
                baseUrl: getRequestBaseUrl(state),
                wishlistedStrollers: getDenormalizedEntities<StrollerEntity>(
                    state,
                    StrollerSchema,
                    strollersComparisonState.strollersIds,
                ),
                requestUser: getRequestUser(state),
                lastCounsellingsPosts: getDenormalizedEntities<PostEntity>(
                    state,
                    PostSchema,
                    homeState.lastCounsellingsPostsIds,
                ),
                lastReviews: getDenormalizedEntities<StrollerUserReviewEntity>(
                    state,
                    StrollerUserReviewSchema,
                    homeState.lastReviewsIds,
                ),
                lastReviewsStrollersMap,
                selectedChart,
                chartStrollers: getDenormalizedEntities<StrollerEntity>(
                    state,
                    StrollerSchema,
                    chartStrollersState.strollerIds,
                ),
                chartStrollersHasMore: chartStrollersState.hasMore,
                chartStrollersLoadingState: chartStrollersState.loadingState,
                thisYearStrollers: getDenormalizedEntities<StrollerEntity>(
                    state,
                    StrollerSchema,
                    homeState.thisYearStrollerIds,
                ),
                brandsCount: homeState.brandsCount,
                buyingGuide: getDenormalizedEntity<ContentItemEntity>(state, ContentItemSchema, homeState.buyingGuideId),
                strollersPreviewPhotoMap,
                strollersPhotosMap,
            };
        };
    }

    public mapDispatchToProps(): MapDispatchToPropsObject<DispatchProps> {
        return {
            onLoad: strollersHomeLoad,
            onLoadChart: strollersHomeLoadChart,
            onSelectChart: strollersHomeSelectChart,
            onStrollersComparisonLoad: strollersComparisonLoad,
            onStrollersComparisonToggle: strollersComparisonToggle,
            onLoadNextRecentArticles: strollersArticlesOfSectionsLoadTrigger,
        };
    }

    public getMetaTitle(props: Props): string {
        return SEO_TITLE_STROLLERS;
    }

    public getMetaDescription(props: Props): string {
        return STROLLER_SEO_GENERAL_DESC;
    }

    public render(props: Props): false | JSX.Element {
        return <Home {...props} />;
    }

    public getHeaderRight(props: Props): React.ReactElement {
        return (
            <>
                <Btn
                    type={BtnType.BlueText}
                    layout={BtnLayout.Icon}
                    icon={faBalanceScaleLeft}
                    count={props.comparison.length}
                    countType={BtnCountType.Circle}
                    link={strollersComparisonUrl}
                />
                <Btn
                    layout={BtnLayout.Icon}
                    type={BtnType.BlueText}
                    icon={faSearch}
                    link={url(strollersSearchUrl, { pageMode: PageMode.SHOW_SEARCH_FORM })}
                />
            </>
        );
    }

    public hasHeaderBackButton(props: Props): boolean {
        return false;
    }

    public getHalfpageProps(props: Props): HalfpageProps {
        return {
            zone: 'Strollers Index - Halfpage Sticky (Desktop)',
        };
    }

    public hasMobileLogo(props: Props): boolean {
        return true;
    }

    public registerInStore(store: Store<AppState>) {
        store.injectReducer('containers.strollers.home', reducer);
        store.injectSaga('containers.strollers.home', saga);

        store.injectReducer('containers.strollers.strollersComparison', strollersComparisonReducer);
        store.injectSaga('containers.strollers.strollersComparison', strollersComparisonSaga);

        store.injectReducer('containers.strollers.articles', strollersArticlesReducer);
        store.injectSaga('containers.strollers.articles', strollersArticlesSaga);
    }

    public getStructuredData(props: Props): object[] {
        return [breadcrumbsStrollers(props.baseUrl)];
    }
}
