import { APIFailureAction, APIRequestAction, APISuccessAction } from 'mk2/helpers/action';
import { NormalizedResponse } from 'mk2/helpers/api';
import { Action } from 'redux';

/**
 * Load strollers of comparison
 */

export const STROLLERS_COMPARISON_LOAD_API_REQUEST = 'STROLLERS_COMPARISON_LOAD_API_REQUEST';

export interface StrollersComparisonLoadApiRequestAction extends APIRequestAction {
    readonly type: typeof STROLLERS_COMPARISON_LOAD_API_REQUEST;
}

export interface StrollersComparisonLoadApiNormalizedResponse extends NormalizedResponse {
    readonly result: {
        strollers: number[]
        strollersPreviewPhotoMap: Record<number, number>;
        strollersPhotos: number[];
    };
}

export const STROLLERS_COMPARISON_LOAD_API_SUCCESS = 'STROLLERS_COMPARISON_LOAD_API_SUCCESS';

export interface StrollersComparisonLoadApiSuccessAction
    extends APISuccessAction<StrollersComparisonLoadApiNormalizedResponse> {
    readonly type: typeof STROLLERS_COMPARISON_LOAD_API_SUCCESS;
    readonly count: number;
    readonly ids: number[];
}

export const STROLLERS_COMPARISON_LOAD_API_FAILURE = 'STROLLERS_COMPARISON_LOAD_API_FAILURE';

export interface StrollersComparisonLoadApiFailureAction extends APIFailureAction {
    readonly type: typeof STROLLERS_COMPARISON_LOAD_API_FAILURE;
}

export const strollersComparisonLoadApi = {
    request: (): StrollersComparisonLoadApiRequestAction => ({
        type: STROLLERS_COMPARISON_LOAD_API_REQUEST,
    }),
    success: (
        count: number,
        ids: number[],
        response: StrollersComparisonLoadApiNormalizedResponse,
    ): StrollersComparisonLoadApiSuccessAction => ({
        type: STROLLERS_COMPARISON_LOAD_API_SUCCESS,
        count,
        ids,
        response,
    }),
    failure: (error: any): StrollersComparisonLoadApiFailureAction => ({
        type: STROLLERS_COMPARISON_LOAD_API_FAILURE,
        error,
    }),
};

export const STROLLERS_COMPARISON_LOAD = 'STROLLERS_COMPARISON_LOAD';

export interface StrollersComparisonLoadAction {
    readonly type: typeof STROLLERS_COMPARISON_LOAD;
}

export const strollersComparisonLoad = (): StrollersComparisonLoadAction => ({
    type: STROLLERS_COMPARISON_LOAD,
});


/**
 * Toggle adding/removing stroller of comparison
 */

export interface StrollersComparisonToggleApiAction extends Action {
    readonly id: number;
}

export const STROLLERS_COMPARISON_TOGGLE_API_REQUEST = 'STROLLERS_COMPARISON_TOGGLE_API_REQUEST';

export interface StrollersComparisonToggleApiRequestAction extends StrollersComparisonToggleApiAction, APIRequestAction {
    readonly type: typeof STROLLERS_COMPARISON_TOGGLE_API_REQUEST;
}

export interface StrollersComparisonToggleApiNormalizedResponse extends NormalizedResponse {
    readonly result: { stroller: number };
}

export const STROLLERS_COMPARISON_TOGGLE_API_SUCCESS = 'STROLLERS_COMPARISON_TOGGLE_API_SUCCESS';

export interface StrollersComparisonToggleApiSuccessAction
    extends StrollersComparisonToggleApiAction,
        APISuccessAction<StrollersComparisonToggleApiNormalizedResponse> {
    readonly type: typeof STROLLERS_COMPARISON_TOGGLE_API_SUCCESS;
    readonly added: boolean;
    readonly ids: number[];
    readonly count: number;
}

export const STROLLERS_COMPARISON_TOGGLE_API_FAILURE = 'STROLLERS_COMPARISON_TOGGLE_API_FAILURE';

export interface StrollersComparisonToggleApiFailureAction extends StrollersComparisonToggleApiAction, APIFailureAction {
    readonly type: typeof STROLLERS_COMPARISON_TOGGLE_API_FAILURE;
}

export const strollersComparisonToggleApi = {
    request: (id: number): StrollersComparisonToggleApiRequestAction => ({
        type: STROLLERS_COMPARISON_TOGGLE_API_REQUEST,
        id,
    }),
    success: (
        id: number,
        added: boolean,
        count: number,
        ids: number[],
        response: StrollersComparisonToggleApiNormalizedResponse,
    ): StrollersComparisonToggleApiSuccessAction => ({
        type: STROLLERS_COMPARISON_TOGGLE_API_SUCCESS,
        id,
        added,
        count,
        ids,
        response,
    }),
    failure: (id: number, error: any): StrollersComparisonToggleApiFailureAction => ({
        type: STROLLERS_COMPARISON_TOGGLE_API_FAILURE,
        id,
        error,
    }),
};

export const STROLLERS_COMPARISON_TOGGLE = 'STROLLERS_COMPARISON_TOGGLE';

export interface StrollersComparisonToggleAction extends StrollersComparisonToggleApiAction {
    readonly type: typeof STROLLERS_COMPARISON_TOGGLE;
}

export const strollersComparisonToggle = (id: number): StrollersComparisonToggleAction => ({
    type: STROLLERS_COMPARISON_TOGGLE,
    id,
});


/**
 * Remove all strollers from the comparison
 */

export interface StrollersComparisonRemoveAllApiAction extends Action {
}

export const STROLLERS_COMPARISON_REMOVE_ALL_API_REQUEST = 'STROLLERS_COMPARISON_REMOVE_ALL_API_REQUEST';

export interface StrollersComparisonRemoveAllApiRequestAction extends StrollersComparisonRemoveAllApiAction, APIRequestAction {
    readonly type: typeof STROLLERS_COMPARISON_REMOVE_ALL_API_REQUEST;
}

export interface StrollersComparisonRemoveAllApiNormalizedResponse extends NormalizedResponse {
    readonly result: {};
}

export const STROLLERS_COMPARISON_REMOVE_ALL_API_SUCCESS = 'STROLLERS_COMPARISON_REMOVE_ALL_API_SUCCESS';

export interface StrollersComparisonRemoveAllApiSuccessAction
    extends StrollersComparisonRemoveAllApiAction,
        APISuccessAction<StrollersComparisonRemoveAllApiNormalizedResponse> {
    readonly type: typeof STROLLERS_COMPARISON_REMOVE_ALL_API_SUCCESS;
}

export const STROLLERS_COMPARISON_REMOVE_ALL_API_FAILURE = 'STROLLERS_COMPARISON_REMOVE_ALL_API_FAILURE';

export interface StrollersComparisonRemoveAllApiFailureAction extends StrollersComparisonRemoveAllApiAction, APIFailureAction {
    readonly type: typeof STROLLERS_COMPARISON_REMOVE_ALL_API_FAILURE;
}

export const strollersComparisonRemoveAllApi = {
    request: (): StrollersComparisonRemoveAllApiRequestAction => ({
        type: STROLLERS_COMPARISON_REMOVE_ALL_API_REQUEST,
    }),
    success: (
        response: StrollersComparisonRemoveAllApiNormalizedResponse,
    ): StrollersComparisonRemoveAllApiSuccessAction => ({
        type: STROLLERS_COMPARISON_REMOVE_ALL_API_SUCCESS,
        response,
    }),
    failure: (error: any): StrollersComparisonRemoveAllApiFailureAction => ({
        type: STROLLERS_COMPARISON_REMOVE_ALL_API_FAILURE,
        error,
    }),
};

export const STROLLERS_COMPARISON_REMOVE_ALL = 'STROLLERS_COMPARISON_REMOVE_ALL';

export interface StrollersComparisonRemoveAllAction extends StrollersComparisonRemoveAllApiAction {
    readonly type: typeof STROLLERS_COMPARISON_REMOVE_ALL;
}

export const strollersComparisonRemoveAll = (): StrollersComparisonRemoveAllAction => ({
    type: STROLLERS_COMPARISON_REMOVE_ALL,
});

/**
 * Store new ordering of compared strollers
 */

export interface StrollersComparisonStoreOrderingApiAction extends Action {
    readonly orderedIds: number[];
}

export const STROLLERS_COMPARISON_STORE_ORDERING_API_REQUEST = 'STROLLERS_COMPARISON_STORE_ORDERING_API_REQUEST';

export interface StrollersComparisonStoreOrderingApiRequestAction extends StrollersComparisonStoreOrderingApiAction, APIRequestAction {
    readonly type: typeof STROLLERS_COMPARISON_STORE_ORDERING_API_REQUEST;
}

export interface StrollersComparisonStoreOrderingApiNormalizedResponse extends NormalizedResponse {
    readonly result: {};
}

export const STROLLERS_COMPARISON_STORE_ORDERING_API_SUCCESS = 'STROLLERS_COMPARISON_STORE_ORDERING_API_SUCCESS';

export interface StrollersComparisonStoreOrderingApiSuccessAction
    extends StrollersComparisonStoreOrderingApiAction,
        APISuccessAction<StrollersComparisonStoreOrderingApiNormalizedResponse> {
    readonly type: typeof STROLLERS_COMPARISON_STORE_ORDERING_API_SUCCESS;
}

export const STROLLERS_COMPARISON_STORE_ORDERING_API_FAILURE = 'STROLLERS_COMPARISON_STORE_ORDERING_API_FAILURE';

export interface StrollersComparisonStoreOrderingApiFailureAction extends StrollersComparisonStoreOrderingApiAction, APIFailureAction {
    readonly type: typeof STROLLERS_COMPARISON_STORE_ORDERING_API_FAILURE;
}

export const strollersComparisonStoreOrderingApi = {
    request: (orderedIds: number[]): StrollersComparisonStoreOrderingApiRequestAction => ({
        type: STROLLERS_COMPARISON_STORE_ORDERING_API_REQUEST,
        orderedIds,
    }),
    success: (
        orderedIds: number[],
        response: StrollersComparisonStoreOrderingApiNormalizedResponse,
    ): StrollersComparisonStoreOrderingApiSuccessAction => ({
        type: STROLLERS_COMPARISON_STORE_ORDERING_API_SUCCESS,
        orderedIds,
        response,
    }),
    failure: (orderedIds: number[], error: any): StrollersComparisonStoreOrderingApiFailureAction => ({
        type: STROLLERS_COMPARISON_STORE_ORDERING_API_FAILURE,
        orderedIds,
        error,
    }),
};

export const STROLLERS_COMPARISON_STORE_ORDERING = 'STROLLERS_COMPARISON_STORE_ORDERING';

export interface StrollersComparisonStoreOrderingAction extends StrollersComparisonStoreOrderingApiAction {
    readonly type: typeof STROLLERS_COMPARISON_STORE_ORDERING;
}

export const strollersComparisonStoreOrdering = (orderedIds: number[]): StrollersComparisonStoreOrderingAction => ({
    type: STROLLERS_COMPARISON_STORE_ORDERING,
    orderedIds,
});


