import { ARTICLES_TITLE, CURRENCY_ISO, FORUM, SEO_TITLE_STROLLERS, WIKI_REVIEWS } from 'mk/autogenerated/translations/structuredData.06f54e91e3a3836a0509bd708614ac30'
import assetUrl from 'mk/common/assetUrl';
import { ProductEntity, ProductStockStatus, XMLImportItemEntity } from 'mk2/apps/bazaar/schemas';
import { forumUrl } from 'mk2/apps/forum/urls';
import {
    strollersArticlesOfSectionUrl,
    strollersArticlesUrl,
    strollersDetailUrl,
    strollersListUrl,
    strollersReviewsUrl,
    strollersUrl,
} from 'mk2/apps/strollers/urls';
import { WIKI_REVIEWS_ARTICLE_SLUG } from 'mk2/apps/wiki/helpers';
import { getWikiArticleUrl } from 'mk2/apps/wiki/urls';
import { getFirstParagraphsPlaintext } from 'mk2/helpers/article_ast/utils';
import { url } from 'mk2/helpers/urls';
import { AST, StrollerBrandEntity, StrollerEntity, StrollerUserReviewEntity } from 'mk2/schemas';
import { BreadcrumbList, ListItem, Product, WithContext } from 'schema-dts';

export function strollerOG(baseUrl: string, stroller: StrollerEntity, description: AST) {
    // according to https://developers.facebook.com/docs/reference/opengraph/object-type/article/

    const image = stroller.previewPhoto?.url250x250 || assetUrl + 'img/general/article-main-img-fallback@1x.png';

    return {
        'og:description': getFirstParagraphsPlaintext(description),
        'og:image': image,
    };
}

const breadcrumbReviews = (baseUrl: string): ListItem => ({
    '@type': 'ListItem',
    'position': 1,
    'item': {
        '@id': baseUrl + getWikiArticleUrl(WIKI_REVIEWS_ARTICLE_SLUG, WIKI_REVIEWS_ARTICLE_SLUG),
        'name': WIKI_REVIEWS,
    },
});


const breadcrumbStrollers = (baseUrl: string): ListItem => ({
    '@type': 'ListItem',
    'position': 2,
    'item': {
        '@id': baseUrl + strollersUrl,
        'name': SEO_TITLE_STROLLERS,
    },
});

const breadcrumbArticles = (baseUrl: string): ListItem => ({
    '@type': 'ListItem',
    'position': 3,
    'item': {
        '@id': baseUrl + strollersArticlesUrl,
        'name': ARTICLES_TITLE,
    },
});

const breadcrumbArticleSection = (baseUrl: string, currentSectionSlug: string, currentSectionLabel: string): ListItem => ({
    '@type': 'ListItem',
    'position': 4,
    'item': {
        '@id': baseUrl + url(strollersArticlesOfSectionUrl, { sectionSlug: currentSectionSlug }),
        'name': currentSectionLabel,
    },
});

export const breadcrumbsStrollers = (baseUrl: string): WithContext<BreadcrumbList> => {
    const items = [breadcrumbReviews(baseUrl), breadcrumbStrollers(baseUrl)];

    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': items,
    };
};

const breadcrumbBrand = (baseUrl: string, brand: StrollerBrandEntity, position = 2): ListItem => ({
    '@type': 'ListItem',
    'position': position,
    'item': {
        '@id': baseUrl + url(strollersListUrl, { slug: brand.slug }),
        'name': brand.name,
        'image': brand.logoPhoto?.url250x250 || brand.strollerPhoto?.url250x250,
    },
});

export const breadcrumbsBrand = (baseUrl: string, brand: StrollerBrandEntity): WithContext<BreadcrumbList> => {
    const items = [breadcrumbReviews(baseUrl), breadcrumbStrollers(baseUrl)];
    if (brand) {
        items.push(breadcrumbBrand(baseUrl, brand));
    }

    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': items,
    };
};

const breadcrumbStroller = (baseUrl: string, stroller: StrollerEntity, position = 3): ListItem => ({
    '@type': 'ListItem',
    'position': position,
    'item': {
        '@id': baseUrl + url(strollersDetailUrl, { slug: stroller.slug }),
        'name': stroller.name,
        'image': stroller.previewPhoto?.url250x250,
    },
});

export const breadcrumbsStroller = (baseUrl: string, stroller: StrollerEntity): WithContext<BreadcrumbList> => {
    const items = [breadcrumbReviews(baseUrl), breadcrumbStrollers(baseUrl)];
    if (stroller) {
        if (stroller.brand) {
            // Brand can be not loaded yet
            items.push(breadcrumbBrand(baseUrl, stroller.brand));
        }
        items.push(breadcrumbStroller(baseUrl, stroller));
    }

    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': items,
    };
};

const breadcrumbForum = (baseUrl: string): ListItem => ({
    '@type': 'ListItem',
    'position': 1,
    'item': {
        '@id': baseUrl + forumUrl,
        'name': FORUM,
    },
});


export const breadcrumbsArticleSections = (baseUrl: string, currentSectionSlug?: string, currentSectionLabel?: string): WithContext<BreadcrumbList> => {
    const items = [breadcrumbReviews(baseUrl), breadcrumbStrollers(baseUrl), breadcrumbArticles(baseUrl)];
    if (currentSectionSlug) {
        items.push(breadcrumbArticleSection(baseUrl, currentSectionSlug, currentSectionLabel));
    }

    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': items,
    };
};

export function productSchemaStroller(
    baseUrl: string,
    stroller: StrollerEntity,
    description: AST,
    reviews: StrollerUserReviewEntity[],
    newProducts: XMLImportItemEntity[],
    oldProduct: ProductEntity,
): WithContext<Product> {
    // fields according to
    //     https://developers.google.com/search/docs/appearance/structured-data/product
    //     https://developers.google.com/search/docs/appearance/structured-data/review-snippet
    if (!reviews.length && !newProducts.length && !oldProduct) {
        // from documentation:
        // Product snippets require either review or aggregateRating or offers
        return null;
    }
    if (!stroller.userScore) {
        // from documentation:
        // 'ratingValue' is required property
        return null;
    }

    const result: WithContext<Product> = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        '@id': url(strollersDetailUrl, { slug: stroller.slug }) + '#Product',
        'name': stroller.name,
        'image': [stroller.previewPhoto?.url250x250],
        'description': getFirstParagraphsPlaintext(description) || '',
        'brand': stroller.brand && {
            // Brand can be not loaded yet
            '@type': 'Brand',
            'name': stroller.brand.name,
            'logo': stroller.brand.logoPhoto?.url250x250 || stroller.brand.strollerPhoto?.url250x250,
        },
        'sku': stroller.id.toString(),
        'url': url(strollersDetailUrl, { slug: stroller.slug }),
        aggregateRating: stroller.userScore !== null
            ? ({
                '@type': 'AggregateRating',
                'bestRating': 5,
                'worstRating': 1,
                'ratingValue': stroller.userScore,
                'reviewCount': reviews.length,
                'ratingCount': reviews.length,
            })
            : undefined,
    };

    if (reviews.length) {
        // Reviews can be not loaded yet, e.g. when user came from HomePage where we do not need all reviews
        result.review = reviews.map((review) => ({
            '@type': 'Review',
            '@id': url(strollersReviewsUrl, { slug: stroller.slug }, {}, `review-${review.id}`),
            'author': {
                '@type': 'Person',
                'name': review.user.username,
            },
            'datePublished': review.createdTime,
            'reviewBody': review.text,
            'name': review.oneliner,
            'reviewRating': {
                '@type': 'Rating',
                'bestRating': 5,
                'ratingValue': review.score,
                'worstRating': 1,
            },
            'url': url(strollersReviewsUrl, { slug: stroller.slug }, {}, `review-${review.id}`),
        }));
    }

    const productsInStock = newProducts.filter(p => p.current.stock === ProductStockStatus.IN_STOCK);
    if (productsInStock.length >= 2) {
        result.offers = {
            '@type': 'AggregateOffer',
            'highPrice': stroller.maxPricedEshopProduct,
            'lowPrice': stroller.minPricedEshopProduct,
            'priceCurrency': CURRENCY_ISO,
            'offerCount': productsInStock.length,
        };
    } else if (productsInStock.length === 1) {
        result.offers = {
            '@type': 'Offer',
            'priceCurrency': CURRENCY_ISO,
            'price': productsInStock[0].current.price,
            'itemCondition': 'https://schema.org/NewCondition',
            'url': baseUrl + url(strollersDetailUrl, { slug: stroller.slug }),
            'availability': 'https://schema.org/InStock',
        };
    } else if (newProducts.length > 0) {
        result.offers = {
            '@type': 'Offer',
            'priceCurrency': CURRENCY_ISO,
            'price': newProducts[0].current.price,
            'itemCondition': 'https://schema.org/NewCondition',
            'url': baseUrl + url(strollersDetailUrl, { slug: stroller.slug }),
            'availability': 'https://schema.org/BackOrder',
        };
    }

    return result;
}
