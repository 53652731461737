import { faBadgeCheck, faHourglass } from '@fortawesome/pro-regular-svg-icons';
import { faImage, faMinusCircle, faPlusCircle, faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import {
    READ_WHOLE_REVIEW,
    REVIEW_OF_ALIKE_MODEL_stroller, REVIEW_VERIFIED,
    STROLLERS_DETAILED_REVIEW,
    STROLLERS_NOT_APPROVED,
} from 'mk/autogenerated/translations/CollapsedReview.9f3f61be56ca1b86c312173b22bdd210'
import { blogsProfileUrl } from 'mk2/apps/blogs/urls';
import { faqVerifiedReviewUrl } from 'mk2/apps/faq/urls';
import { strollersDetailUrl, strollersReviewsUrl } from 'mk2/apps/strollers/urls';
import { AvatarImg } from 'mk2/components/AvatarImg';
import { Clamp } from 'mk2/components/Clamp';
import { Dot } from 'mk2/components/Dot';
import { Emojify } from 'mk2/components/Emojify';
import { Interpolate } from 'mk2/components/Interpolate';
import { Link } from 'mk2/components/Link';
import { StarsRating } from 'mk2/components/StarsRating';
import { timeAt } from 'mk2/helpers/timeformat';
import { url } from 'mk2/helpers/urls';
import { StrollerEntity, StrollerUserReviewEntity, StrollerUserReviewStatus } from 'mk2/schemas';
import React from 'react';
import styles from './CollapsedReview.mscss';

interface OwnProps {
    className?: string;
    stroller: StrollerEntity;         // kocik ku ktoremu zobrazujeme recenziu
    review: StrollerUserReviewEntity;
    // FIXME ma byt povinny prop, inak sa nespravne zobrazuje
    reviewStroller?: StrollerEntity;  // kocik ku ktoremu bola napisana recenzia (moze byt suvisiaci model)
    hideUser?: boolean;
}

export const CollapsedReview: React.FunctionComponent<OwnProps> = ({
    stroller, review, reviewStroller, className, hideUser,
}) => {
    // FIXME backward compatibility only, should be prop, ma nespravnu hodnotu
    reviewStroller = reviewStroller || stroller;

    if (!reviewStroller || review.strollerId !== reviewStroller.id) {
        throw new Error('Review stroller mismatch');
    }

    const components = (
        <>
            {stroller.id !== review.strollerId && (
                <Interpolate
                    className={styles.CollapsedReview__alike}
                    parent="div"
                    i18nKey={REVIEW_OF_ALIKE_MODEL_stroller}
                    stroller={(
                        <Link to={url(strollersDetailUrl, { slug: reviewStroller.slug })}>
                            {reviewStroller.name}
                        </Link>
                    )}
                />
            )}

            {review.user && !hideUser && (
                <div className={styles.CollapsedReview__user}>
                    <AvatarImg size={24} user={review.user} className={styles.CollapsedReview__user__avatar} />
                    <Link
                        to={url(blogsProfileUrl, { username: review.user.username })}
                        className={styles.CollapsedReview__user__username}
                    >
                        {review.user.username}
                    </Link>
                    <Dot spaces={1} className={styles.CollapsedReview__user__dot} />
                    <span className={styles.CollapsedReview__user__createdAt}>{timeAt(review.createdTime)}</span>
                    {review.status === StrollerUserReviewStatus.VERIFIED_AND_APPROVED &&
                        <span>
                            <Link to={faqVerifiedReviewUrl} className={styles.CollapsedReview__user__verified}>
                                <FontAwesomeIcon icon={faBadgeCheck} /> {REVIEW_VERIFIED}
                            </Link>
                        </span>
                    }
                </div>
            )}

            <div className={styles.CollapsedReview__score}>
                <StarsRating starsCount={review.score} canShowHalf />
                {review.approved && review.detailedReview ? (
                    <>
                        <Dot spaces={1} />
                        <div className={cx(styles.CollapsedReview__score__badge, styles['CollapsedReview__score__badge--detailedReview'])}>
                            <FontAwesomeIcon icon={faStar} className={styles.CollapsedReview__score__icon} />
                            &nbsp;
                            {STROLLERS_DETAILED_REVIEW}
                        </div>
                    </>
                ) : null}
                {review.approved === false && !review.deleted ? (
                    <>
                        <Dot spaces={1} />
                        <div className={styles.CollapsedReview__score__badge}>
                            <FontAwesomeIcon icon={faHourglass} className={styles.CollapsedReview__score__icon} />
                            &nbsp;
                            {STROLLERS_NOT_APPROVED}
                        </div>
                    </>
                ) : null}
            </div>

            <div className={styles.CollapsedReview__oneliner}>
                <Emojify>{review.oneliner}</Emojify>
            </div>

            <div className={styles.CollapsedReview__perex}>
                <Clamp clamp={2}>
                    <div>
                        {review.text}
                    </div>
                </Clamp>
            </div>

            {(
                review.photos.length +
                review.negatives.length +
                review.positives.length +
                (review.showDetailLink ? 1 : 0)
            ) > 0 && (
                <div className={styles.CollapsedReview__footer}>
                    {review.photos.length > 0 && (
                        <span
                            className={cx(
                                styles.CollapsedReview__footer__badge,
                                styles.CollapsedReview__footer__imagesCount,
                            )}
                        >
                            <FontAwesomeIcon
                                className={cx(
                                    styles.CollapsedReview__footer__badge__icon,
                                    styles.CollapsedReview__footer__imagesCount__icon,
                                )}
                                icon={faImage}
                            />{' '}
                            {review.photos.length}
                        </span>
                    )}

                    {review.positives.length > 0 && (
                        <span
                            className={cx(
                                styles.CollapsedReview__footer__badge,
                                styles.CollapsedReview__footer__positivesCount,
                            )}
                        >
                            <FontAwesomeIcon
                                className={cx(
                                    styles.CollapsedReview__footer__badge__icon,
                                    styles.CollapsedReview__footer__positivesCount__icon,
                                )}
                                icon={faPlusCircle}
                            />{' '}
                            {review.positives.length}
                        </span>
                    )}

                    {review.negatives.length > 0 && (
                        <span
                            className={cx(
                                styles.CollapsedReview__footer__badge,
                                styles.CollapsedReview__footer__negativesCount,
                            )}
                        >
                            <FontAwesomeIcon
                                className={cx(
                                    styles.CollapsedReview__footer__badge__icon,
                                    styles.CollapsedReview__footer__negativesCount__icon,
                                )}
                                icon={faMinusCircle}
                            />{' '}
                            {review.negatives.length}
                        </span>
                    )}
                    {review.showDetailLink && !review.deleted && (
                        <div className={styles.CollapsedReview__seeMore}>{READ_WHOLE_REVIEW}</div>
                    )}
                </div>
            )}
        </>
    );

    return !review.deleted ? (
        <Link
            to={url(strollersReviewsUrl, { slug: stroller.slug }, {}, `review-${review.id}`)}
            className={cx(
                styles.CollapsedReview, className,
                (review.status !== StrollerUserReviewStatus.NOT_VERIFIED_AND_APPROVED && review.status !== StrollerUserReviewStatus.VERIFIED_AND_APPROVED) && styles['CollapsedReview--notApproved'],
            )}
        >
            {components}
        </Link>
    ) : (
        <div className={cx(styles.CollapsedReview, className)}>
            {components}
        </div>
    );

};

CollapsedReview.displayName = 'CollapsedReview';
