// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const STROLLERS_HOME_TIPS_FROM_MUMS_MORE_BY_IN_BAZAAR = "Zobraz dal\u0161\u00ed ko\u010d\u00e1rky (10)";
export const STROLLERS_HOME_TIPS_FROM_MUMS_MORE_BY_SCORE = "Zobraz dal\u0161\u00ed ko\u010d\u00e1rky (10)";
export const STROLLERS_HOME_TIPS_FROM_MUMS_MORE_HAPPY_MEDIUM = "Zobraz dal\u0161\u00ed Zlat\u00fd st\u0159ed ko\u010d\u00e1rky (10)";
export const STROLLERS_HOME_TIPS_FROM_MUMS_MORE_POPULAR = "Zobraz dal\u0161\u00ed ko\u010d\u00e1rky za babku (10)";
export const STROLLERS_HOME_TIPS_FROM_MUMS_MORE_TOP = "Zobraz dal\u0161\u00ed TOP ko\u010d\u00e1rky (10)";
export const STROLLERS_HOME_TIPS_FROM_MUMS_TAB_HAPPY_MEDIUM = "Zlat\u00fd st\u0159ed";
export const STROLLERS_HOME_TIPS_FROM_MUMS_TAB_IN_BAZAAR = "Nejlep\u0161\u00ed v bazaru";
export const STROLLERS_HOME_TIPS_FROM_MUMS_TAB_POPULAR = "Za babku";
export const STROLLERS_HOME_TIPS_FROM_MUMS_TAB_SCORE = "Nejl\u00e9pe hodnocen\u00e9";
export const STROLLERS_HOME_TIPS_FROM_MUMS_TAB_TOP = "TOPky";
