// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const counter_RATINGS = ["%(counter)s hodnocen\u00ed", "%(counter)s hodnocen\u00ed", "%(counter)s hodnocen\u00ed"];
export const STROLLER_CARD_AIRLESS_WHEELS = "kole\u010dka nenafukovac\u00ed s v\u00fdpln\u00ed";
export const STROLLER_CARD_AIR_WHEELS = "kole\u010dka nafukovac\u00ed";
export const STROLLER_CARD_BIG_SHOPPING_BASKET = "velk\u00fd \u00falo\u017en\u00fd prostor";
export const STROLLER_CARD_CARRYCOT_DIMS_SPACED = "velk\u00e1 vani\u010dka";
export const STROLLER_CARD_CAR_SEAT_CAN_BE_ATTACHED = "mo\u017enost p\u0159ipojen\u00ed autoseda\u010dky";
export const STROLLER_CARD_COMPARE = "Porovnej";
export const STROLLER_CARD_COMPARE_REMOVE = "Odeber";
export const STROLLER_CARD_FOAM_WHEELS = "kole\u010dka p\u011bnov\u00e1";
export const STROLLER_CARD_FOLDABLE_CARRYCOT = "skl\u00e1dac\u00ed vani\u010dka";
export const STROLLER_CARD_MANUAL_BRAKE_PRESENT = "brzda na rukojeti";
export const STROLLER_CARD_MAXIMUM_LOAD_PER_CHILD_IN_kg = "nosnost seda\u010dky %(kg)s kg";
export const STROLLER_CARD_ONE_HAND_FOLDING = "slo\u017een\u00ed jednou rukou";
export const STROLLER_CARD_PLASTIC_WHEELS = "plastov\u00e9 kole\u010dka";
export const STROLLER_CARD_REAR_WHEELS_DIAMETER_IN_cm = "zadn\u00ed kole\u010dka \u2300%(cm)s cm";
export const STROLLER_CARD_RECOMMENDED_CHOICE_HAPPY_MEDIUM = "Zlat\u00fd st\u0159ed";
export const STROLLER_CARD_RECOMMENDED_CHOICE_POPULAR = "Za babku";
export const STROLLER_CARD_RECOMMENDED_CHOICE_TOP = "TOP";
export const STROLLER_CARD_ROTATING_FIXATION_WHEELS_FIXED = "fixn\u00e9";
export const STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING = "oto\u010dn\u00e1";
export const STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING_LOCKABLE = "oto\u010dn\u00e1 s mo\u017enost\u00ed fixace";
export const STROLLER_CARD_ROTATING_SEAT = "oto\u010dn\u00e1 seda\u010dka";
export const STROLLER_CARD_SPACIOUS_SEAT = "prostorn\u00e1 seda\u010dka";
export const STROLLER_CARD_UNFOLDED_DIMENSIONS_IN_cm_WIDTH = "\u0161\u00ed\u0159ka ko\u010d\u00e1rku %(cm)s cm";
export const STROLLER_CARD_VERTICAL_SEAT  = "Z\u00e1dov\u00e1 op\u011brka jde polohovat do kolm\u00e9ho sedu (90\u00b0)";
export const STROLLER_CARD_VERY_GOOD_FOLDING = "velmi skladn\u00fd";
export const STROLLER_CARD_WEIGHT_IN_kg = "hmotnost ko\u010d\u00e1rku %(kg)s kg";
export const STROLLER_CARD_WEIGHT_IS_LOW = "velmi lehk\u00fd";
export const STROLLER_CARD_WHEELS = "kole\u010dka";
export const STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_SHORT = "vhodn\u00fd pro n\u00edzk\u00e9 rodi\u010de";
export const STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_TALL = "vhodn\u00fd pro vysok\u00e9 rodi\u010de";
export const STROLLER_UNPREPARED_TAG = "P\u0159ipravujeme";
