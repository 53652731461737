import { faBalanceScaleLeft } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import {
    counter_RATINGS,
    STROLLER_CARD_AIR_WHEELS,
    STROLLER_CARD_AIRLESS_WHEELS,
    STROLLER_CARD_BIG_SHOPPING_BASKET,
    STROLLER_CARD_CAR_SEAT_CAN_BE_ATTACHED,
    STROLLER_CARD_CARRYCOT_DIMS_SPACED,
    STROLLER_CARD_COMPARE,
    STROLLER_CARD_COMPARE_REMOVE,
    STROLLER_CARD_FOAM_WHEELS,
    STROLLER_CARD_FOLDABLE_CARRYCOT,
    STROLLER_CARD_MANUAL_BRAKE_PRESENT,
    STROLLER_CARD_MAXIMUM_LOAD_PER_CHILD_IN_kg,
    STROLLER_CARD_ONE_HAND_FOLDING,
    STROLLER_CARD_PLASTIC_WHEELS,
    STROLLER_CARD_REAR_WHEELS_DIAMETER_IN_cm,
    STROLLER_CARD_RECOMMENDED_CHOICE_HAPPY_MEDIUM,
    STROLLER_CARD_RECOMMENDED_CHOICE_POPULAR,
    STROLLER_CARD_RECOMMENDED_CHOICE_TOP,
    STROLLER_CARD_ROTATING_FIXATION_WHEELS_FIXED,
    STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING,
    STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING_LOCKABLE,
    STROLLER_CARD_ROTATING_SEAT,
    STROLLER_CARD_SPACIOUS_SEAT,
    STROLLER_CARD_UNFOLDED_DIMENSIONS_IN_cm_WIDTH,
    STROLLER_CARD_VERTICAL_SEAT,
    STROLLER_CARD_VERY_GOOD_FOLDING,
    STROLLER_CARD_WEIGHT_IN_kg,
    STROLLER_CARD_WEIGHT_IS_LOW,
    STROLLER_CARD_WHEELS,
    STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_SHORT,
    STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_TALL,
    STROLLER_UNPREPARED_TAG,
} from 'mk/autogenerated/translations/StrollerCard.b918fa7f48d1421364c015e3a7688998'
import { StrollerPrices, StrollerPricesPlaceholder } from 'mk2/apps/strollers/components/StrollerPrices';
import { dummyPhoto } from 'mk2/apps/strollers/constants';
import { FEATURES_LIST } from 'mk2/apps/strollers/features';
import { getProductIsNoLongerSoldLabel } from 'mk2/apps/strollers/helpers';
import { strollersDetailUrl, strollersDetailVariantUrl, strollersReviewsUrl } from 'mk2/apps/strollers/urls';
import { Btn, BtnType } from 'mk2/components/Btn';
import { ColorVariants } from 'mk2/components/ColorVariants';
import { Dot } from 'mk2/components/Dot';
import { ImgCropped, ImgCropMode } from 'mk2/components/ImgCropped';
import { Interpolate } from 'mk2/components/Interpolate';
import { Link } from 'mk2/components/Link';
import { LoadingPlaceholder } from 'mk2/components/LoadingPlaceholder';
import { StarsRating } from 'mk2/components/StarsRating';
import { url } from 'mk2/helpers/urls';
import useEventCallback from 'mk2/hooks/useEventCallback';
import { Features, StrollerEntity, StrollerPhoto, StrollerVariantEntity, VariantAttributesWithOptions } from 'mk2/schemas';
import { interpolate } from 'mk2/services/i18n';
import React, { useState } from 'react';
import styles from './StrollerCard.mscss';

interface OwnProps {
    className?: string;
    style?: React.CSSProperties;
    stroller: Pick<StrollerEntity,
        'id' | 'name' | 'slug' | 'userScore' | 'prepared' | 'configurables' |
        'minPricedEshopProduct' | 'maxPricedEshopProduct' |
        'priceUsedMin' | 'priceUsedMax' | 'reviewsCount'
    > & {
        previewPhoto: Pick<StrollerPhoto, 'originalHeight' | 'originalWidth' | 'url720x720'>;
        features: Pick<
            Features,
            | 'type'
            | 'wheel_description_sk'
            | 'wheel_description_cz'
            | 'rotating_fixation_wheels'
            | 'back_support_can_be_in_vertical_seat_position'
            | 'rotating_seat'
            | 'carrycot_dims_in_cm'
            | 'spacious_seat'
            | 'weight_is_low'
            | 'very_good_folding'
            | 'big_shopping_basket'
            | 'manual_brake_present'
            | 'one_hand_folding'
            | 'foldable_carrycot'
            | 'back_support_can_be_in_lying_position'
            | 'car_seat_can_be_attached'
            | 'rear_wheels_diameter_in_cm'
            | 'weight_in_kg'
            | 'maximum_load_per_child_in_kg'
            | 'unfolded_dimensions_in_cm'
            | 'recommended_choice'
            | 'product_is_no_longer_sold_bool'
            | 'product_is_no_longer_sold_int'
            | 'stroller_for_parent_by_height'
        >;
        variantAttributes: Pick<
            VariantAttributesWithOptions,
            'variant_rear_wheel_type'
            | 'variant_front_wheel_type'
        >;
    };
    strollerPhoto: StrollerPhoto;

    variant?: StrollerVariantEntity;
    variantsPreviewPhotoMap?: Record<number, number>;
    photoObjectsMap?: Record<number, StrollerPhoto>;

    // TODO: Temporary fields - we do not know model yet
    colorsVariants?: any[];
    // topBadge?: number;
    isInComparison: boolean;

    searchData?: any;

    onComparisonToggle(strollerId: number);
}

type Props = OwnProps;

export const StrollerCard: React.FunctionComponent<Props> = React.memo(({
    className,
    style,
    stroller,
    strollerPhoto,
    variant,
    variantsPreviewPhotoMap,
    photoObjectsMap,
    colorsVariants,
    isInComparison,
    onComparisonToggle,
    searchData,
}) => {

    let firstPhoto: Pick<StrollerPhoto, 'originalHeight' | 'originalWidth' | 'url720x720'> = dummyPhoto;
    if (!!variant && variant.id in variantsPreviewPhotoMap && variantsPreviewPhotoMap[variant.id] in photoObjectsMap) {
            firstPhoto = photoObjectsMap[variantsPreviewPhotoMap[variant.id]];
    } else if (!!stroller && strollerPhoto) {
        firstPhoto = strollerPhoto;
    }

    const linkUrl = !!variant ? url(strollersDetailVariantUrl, { slug: stroller.slug, variantId: variant.id}) : url(strollersDetailUrl, { slug: stroller.slug });

    const productIsNoLongerSold = getProductIsNoLongerSoldLabel(stroller);
    colorsVariants = stroller.configurables !== null ? stroller.configurables.config_color : null;

    const features = [];
    if (stroller.variantAttributes.variant_rear_wheel_type?.indexOf('air_wheels') >= 0) {
        if (stroller.features.rotating_fixation_wheels === 'fixed') {
            features.push(`${STROLLER_CARD_AIR_WHEELS} (${STROLLER_CARD_ROTATING_FIXATION_WHEELS_FIXED})`);
        } else if (stroller.features.rotating_fixation_wheels === 'rotating') {
            features.push(`${STROLLER_CARD_AIR_WHEELS} (${STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING})`);
        } else if (stroller.features.rotating_fixation_wheels === 'rotating lockable') {
            features.push(`${STROLLER_CARD_AIR_WHEELS} (${STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING_LOCKABLE})`);
        }
    } else if (stroller.variantAttributes.variant_rear_wheel_type?.indexOf('airless_wheels') >= 0) {
        if (stroller.features.rotating_fixation_wheels === 'fixed') {
            features.push(`${STROLLER_CARD_AIRLESS_WHEELS} (${STROLLER_CARD_ROTATING_FIXATION_WHEELS_FIXED})`);
        } else if (stroller.features.rotating_fixation_wheels === 'rotating') {
            features.push(`${STROLLER_CARD_AIRLESS_WHEELS} (${STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING})`);
        } else if (stroller.features.rotating_fixation_wheels === 'rotating lockable') {
            features.push(
                `${STROLLER_CARD_AIRLESS_WHEELS} (${STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING_LOCKABLE})`,
            );
        }
    } else if (stroller.variantAttributes.variant_rear_wheel_type?.indexOf('foam_wheels') >= 0) {
        if (stroller.features.rotating_fixation_wheels === 'fixed') {
            features.push(`${STROLLER_CARD_FOAM_WHEELS} (${STROLLER_CARD_ROTATING_FIXATION_WHEELS_FIXED})`);
        } else if (stroller.features.rotating_fixation_wheels === 'rotating') {
            features.push(`${STROLLER_CARD_FOAM_WHEELS} (${STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING})`);
        } else if (stroller.features.rotating_fixation_wheels === 'rotating lockable') {
            features.push(`${STROLLER_CARD_FOAM_WHEELS} (${STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING_LOCKABLE})`);
        }
    } else if (stroller.variantAttributes.variant_rear_wheel_type?.indexOf('plastic_wheels') >= 0) {
        if (stroller.features.rotating_fixation_wheels === 'fixed') {
            features.push(`${STROLLER_CARD_PLASTIC_WHEELS} (${STROLLER_CARD_ROTATING_FIXATION_WHEELS_FIXED})`);
        } else if (stroller.features.rotating_fixation_wheels === 'rotating') {
            features.push(`${STROLLER_CARD_PLASTIC_WHEELS} (${STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING})`);
        } else if (stroller.features.rotating_fixation_wheels === 'rotating lockable') {
            features.push(
                `${STROLLER_CARD_PLASTIC_WHEELS} (${STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING_LOCKABLE})`,
            );
        }
    } else {
        if (stroller.features.rotating_fixation_wheels === 'fixed') {
            features.push(`${STROLLER_CARD_WHEELS} ${STROLLER_CARD_ROTATING_FIXATION_WHEELS_FIXED}`);
        } else if (stroller.features.rotating_fixation_wheels === 'rotating') {
            features.push(`${STROLLER_CARD_WHEELS} ${STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING}`);
        } else if (stroller.features.rotating_fixation_wheels === 'rotating lockable') {
            features.push(`${STROLLER_CARD_WHEELS} ${STROLLER_CARD_ROTATING_FIXATION_WHEELS_ROTATING_LOCKABLE}`);
        }
    }
    if (stroller.features.stroller_for_parent_by_height) {
        if (stroller.features.stroller_for_parent_by_height === 'height_lt_150') {
            features.push(STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_SHORT);
        } else if (stroller.features.stroller_for_parent_by_height === 'height_gt_170') {
            features.push(STROLLER_FEATURE_STROLLER_FOR_PARENT_BY_HEIGHT_TALL);
        }
    }
    if (stroller.features.back_support_can_be_in_lying_position !== 'no') {
        features.push(FEATURES_LIST.back_support_can_be_in_lying_position.OPTIONS[stroller.features.back_support_can_be_in_lying_position]);
    }
    if (stroller.features.back_support_can_be_in_vertical_seat_position) {
        features.push(STROLLER_CARD_VERTICAL_SEAT);
    }
    if (stroller.features.rotating_seat) {
        features.push(STROLLER_CARD_ROTATING_SEAT);
    }
    if (stroller.features.carrycot_dims_in_cm?.some((v) => Math.max(v.width, v.depth) >= 78)) {
        features.push(STROLLER_CARD_CARRYCOT_DIMS_SPACED);
    }
    if (stroller.features.spacious_seat) {
        features.push(STROLLER_CARD_SPACIOUS_SEAT);
    }
    if (stroller.features.weight_is_low) {
        features.push(STROLLER_CARD_WEIGHT_IS_LOW);
    }
    if (stroller.features.very_good_folding) {
        features.push(STROLLER_CARD_VERY_GOOD_FOLDING);
    }
    if (stroller.features.big_shopping_basket) {
        features.push(STROLLER_CARD_BIG_SHOPPING_BASKET);
    }
    if (stroller.features.manual_brake_present) {
        features.push(STROLLER_CARD_MANUAL_BRAKE_PRESENT);
    }
    if (stroller.features.one_hand_folding) {
        features.push(STROLLER_CARD_ONE_HAND_FOLDING);
    }
    if (stroller.features.foldable_carrycot) {
        features.push(STROLLER_CARD_FOLDABLE_CARRYCOT);
    }
    if (
        stroller.features.car_seat_can_be_attached === 'yes_without_adapters' ||
        stroller.features.car_seat_can_be_attached === 'yes_with_adapters'
    ) {
        // Check if
        features.push(STROLLER_CARD_CAR_SEAT_CAN_BE_ATTACHED);
    }
    if (stroller.features.rear_wheels_diameter_in_cm) {
        features.push(
            interpolate(STROLLER_CARD_REAR_WHEELS_DIAMETER_IN_cm, {
                cm: stroller.features.rear_wheels_diameter_in_cm,
            }),
        );
    }
    if (stroller.features.weight_in_kg) {
        features.push(
            interpolate(STROLLER_CARD_WEIGHT_IN_kg, {
                kg: stroller.features.weight_in_kg,
            }),
        );
    }
    if (stroller.features.maximum_load_per_child_in_kg) {
        features.push(
            interpolate(STROLLER_CARD_MAXIMUM_LOAD_PER_CHILD_IN_kg, {
                kg: stroller.features.maximum_load_per_child_in_kg,
            }),
        );
    }
    if (stroller.features.unfolded_dimensions_in_cm) {
        features.push(
            interpolate(STROLLER_CARD_UNFOLDED_DIMENSIONS_IN_cm_WIDTH, {
                cm: stroller.features.unfolded_dimensions_in_cm.width,
            }),
        );
    }

    const onComparisonToggleCallback = useEventCallback(() => {
        onComparisonToggle(stroller.id);
    }, [stroller.id, onComparisonToggle]);

    return (
        <div className={cx(styles.StrollerCard, className)} style={style}>
            <div className={styles.StrollerCard__left}>
                <Link className={styles.StrollerCard__image} to={linkUrl}>
                    <ImgCropped
                        className={styles.StrollerCard__image__image}
                        imgUrl={firstPhoto.url720x720}
                        lazy={false}
                        imgWidth={firstPhoto.originalWidth}
                        imgHeight={firstPhoto.originalHeight}
                        mode={ImgCropMode.Embed}
                        width={130}
                        height={130}
                    />
                </Link>

                {colorsVariants && (
                    <div className={styles.StrollerCard__colors}>
                        <ColorVariants colors={colorsVariants} maxShown={4} />
                    </div>

                )}
                {stroller.features.recommended_choice && (
                    <div className={styles.StrollerCard__badges}>
                        <span
                            className={cx(
                                styles.StrollerCard__badges__badge,
                                stroller.features.recommended_choice === 'top' &&
                                    styles['StrollerCard__badges__badge--top'],
                                stroller.features.recommended_choice === 'happy medium' &&
                                    styles['StrollerCard__badges__badge--happyMedium'],
                                stroller.features.recommended_choice === 'popular' &&
                                    styles['StrollerCard__badges__badge--popular'],
                            )}
                        >
                            {stroller.features.recommended_choice === 'top'
                                ? STROLLER_CARD_RECOMMENDED_CHOICE_TOP
                                : stroller.features.recommended_choice === 'happy medium'
                                ? STROLLER_CARD_RECOMMENDED_CHOICE_HAPPY_MEDIUM
                                : stroller.features.recommended_choice === 'popular'
                                ? STROLLER_CARD_RECOMMENDED_CHOICE_POPULAR
                                : null}
                        </span>
                    </div>
                )}
                <div className={styles.StrollerCard__compare}>
                    <Btn
                        type={isInComparison ? BtnType.RedText : BtnType.Link}
                        label={isInComparison ? STROLLER_CARD_COMPARE_REMOVE : STROLLER_CARD_COMPARE}
                        icon={faBalanceScaleLeft}
                        onClick={onComparisonToggleCallback}
                    />
                </div>
            </div>
            <div className={styles.StrollerCard__right}>
                <Link className={styles.StrollerCard__name} to={linkUrl}>
                    {stroller.name}
                </Link>
                {(!stroller.prepared || productIsNoLongerSold) && (
                    <div className={styles.StrollerCard__tags}>
                        {!stroller.prepared && (
                            <span className={styles.StrollerCard__tags__unpreparedTag}>{STROLLER_UNPREPARED_TAG}</span>
                        )}
                        {productIsNoLongerSold && (
                            <span className={styles.StrollerCard__tags__productIsNoLongerSoldTag}>
                                {productIsNoLongerSold}
                            </span>
                        )}
                    </div>
                )}{' '}
                <div className={styles.StrollerCard__reviews}>
                    {stroller.userScore !== null && (
                        <>
                            <StarsRating
                                className={styles.StrollerCard__reviews__score}
                                starsCount={stroller.userScore}
                                canShowHalf
                            />
                            <Dot spaces={1} />
                        </>
                    )}
                   <Link to={ url(strollersReviewsUrl, { slug: stroller.slug }) }>
                       <Interpolate
                        className={styles.StrollerCard__reviews__count}
                        i18nKey={counter_RATINGS}
                        counter={stroller.reviewsCount}
                        count={stroller.reviewsCount}
                        />
                   </Link>
                </div>
                <StrollerPrices
                    priceUsedMin={stroller.priceUsedMin}
                    priceUsedMax={stroller.priceUsedMax}
                    minPricedEshopProductPrice={!!variant ? variant?.minPricedEshopProduct : stroller.minPricedEshopProduct}
                    maxPricedEshopProductPrice={!!variant ? variant?.maxPricedEshopProduct : stroller.maxPricedEshopProduct}
                />
                <div className={styles.StrollerCard__type}>
                    <FEATURES_LIST.type.Component
                        className={styles.StrollerCard__type__feature}
                        features={stroller.features}
                        feature={FEATURES_LIST.type}
                        searchData={searchData?.type}
                    />
                </div>
                <div className={styles.StrollerCard__features}>
                    {features.map((feature, index) => (
                        <React.Fragment key={feature}>
                            {feature}
                            {index < features?.length - 1 && <>&nbsp;• </>}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
});

interface PlaceholderOwnProps {
    className?: string;
    style?: React.CSSProperties;
}

export const StrollerCardPlaceholder: React.FunctionComponent<PlaceholderOwnProps> = ({ className, style }) => (
    <div className={cx(styles.StrollerCard, className)} style={style}>
        <div className={styles.StrollerCard__left}>
            <span className={styles.StrollerCard__image}>
                <LoadingPlaceholder width={130} height={130} />
            </span>
            <div className={styles.StrollerCard__compare}>
                <LoadingPlaceholder width={80} height={34} />
            </div>
        </div>
        <div className={styles.StrollerCard__right}>
            <span className={styles.StrollerCard__name}>
                <LoadingPlaceholder width={140} />
            </span>
            <div className={styles.StrollerCard__reviews}>
                <LoadingPlaceholder width={215} />
            </div>
            <StrollerPricesPlaceholder />
            <div className={styles.StrollerCard__type}>
                <LoadingPlaceholder width={60} />
            </div>
            <div className={styles.StrollerCard__features}>
                <LoadingPlaceholder width="100%" height={100} />
            </div>
        </div>
    </div>
);

StrollerCardPlaceholder.displayName = 'StrollerCardPlaceholder';
